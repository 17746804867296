import React, { useState,useEffect } from "react";
import "./MainLayout.css";
import MainLeftSideBar from "./MainLeftSideBar/MainLeftSideBar";
import MainRightSidebar from "./MainRightSidebar/MainRightSidebar";
import MainLayoutArea from "./MainLayoutArea/MainLayoutArea";
import LandingHeader from "../LandingLayout/LandingHeader/LandingHeader";
import EditorFooter from "./EditorLayout/EditorFooter/EditorFooter";
import toggleFooterBtn from "../../Assets/Icons/arrow-up-double-sharp.png";
import { ShapeProvider } from "../../contexts/shapeContext";

function Sidebar({ side, onToggle, setShapeToDraw }) {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    onToggle(!collapsed);
  };

  return (
    <>
      <div className="position-relative border-start">
        {side === "right" && (
          <div
            className="handle d-flex mt-3  justify-content-basel align-items-center position-absolute top-0  start-0"
            onClick={handleToggle}
          >
            <button className="rightBtn border-end-0 rounded-4">
              <div className="triangle-right w-auto">
                <img
                  src={toggleFooterBtn}
                  className="img-fluid rightImg"
                  alt="ss"
                />
              </div>
            </button>
          </div>
        )}
        <aside className={`sidebar1 ${side}-sidebar ${collapsed ? "collapsed" : ""}`}>
          <div className="sidebar-content">
            {side === "left" ? (
              <div className="scrollable">
                <MainLeftSideBar setShapeToDraw={setShapeToDraw} />
              </div>
            ) : (
              <div className="scrollable">
                <MainRightSidebar />
              </div>
            )}
          </div>
        </aside>
        {side === "left" && (
          <div
            className="handle d-flex mt-3  justify-content-basel align-items-center position-absolute top-0  end-0"
            onClick={handleToggle}
          >
            {/* <button className="z-1 leftBtn border-start-0 rounded-4">
              <div className="triangle-left w-auto">
                <img
                  src={toggleFooterBtn}
                  className="img-fluid leftImg"
                  alt="ss"
                />
              </div>
            </button> */}
          </div>
        )}
      </div>
    </>
  );
}

function MainLayout() {
  const [leftCollapsed, setLeftCollapsed] = useState(false);
  const [rightCollapsed, setRightCollapsed] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // State for zoom level
  const [shapeToDraw, setShapeToDraw]= useState('');
  const [filePreview,setFilePreview] = useState('')


  // const [sidebarOpen, setSidebarOpen] = useState(false);
  // const [darkMode, setDarkMode] = useState(false); // State for theme
  // const [rightSidebarOpen, setRightSidebarOpen] = useState(false); // State for right sidebar

  // const toggleSidebar = () => {
  //   setSidebarOpen(!sidebarOpen);
  // };

  // const toggleTheme = () => {
  //   setDarkMode(!darkMode);
  // };

  // const toggleRightSidebar = () => {
  //   setRightSidebarOpen(!rightSidebarOpen);
  // };

  const toggleLeft = () => {
    setLeftCollapsed(!leftCollapsed);
  };

  const toggleRight = () => {
    setRightCollapsed(!rightCollapsed);
  };
  const handleZoomChange = (newZoomLevel) => {
    setZoomLevel(newZoomLevel);
  };

  const filesPreview = (filePreview) =>{
    setFilePreview(filePreview)
    console.log('first2332',filePreview)
  }

  useEffect(() => {

  }, [setFilePreview])
  

  return (
    <div >
       <ShapeProvider>
          <div className='layout-container '>
            <div className="sticky-top">
              <LandingHeader filesPreview={filesPreview}/>
            </div>
            <div className="editorAreaHeight">
            <div className="content">
              <Sidebar side="left" onToggle={toggleLeft}  setShapeToDraw={setShapeToDraw} />
              <div className="main11">
              <MainLayoutArea zoomLevel={zoomLevel} filePreview={filePreview} shapeToDraw={shapeToDraw} setShapeToDraw={setShapeToDraw} />
              </div>
              <Sidebar side="right" onToggle={toggleRight} />
            </div>
            </div>

              <div className="">
              <EditorFooter onZoomChange={handleZoomChange} />
              </div>
        
          </div>  
    </ShapeProvider>
    </div>
  );
}

export default MainLayout;
