import React, { useState, useEffect, useRef } from "react";
import "./ToolSideBar.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';
import AddProject from "../../../../../Assets/Icons/AddProjectIcon.png";
import CursorMoveIcon from "../../../../../Assets/Icons/CursorMoveIcon.png";
import BrushIcon from "../../../../../Assets/Icons/BrushIcon.png";
import PenToolIcon from "../../../../../Assets/Icons/PenToolIcon.png";
import FreeDrawIcon from "../../../../../Assets/Icons/FreeDraw.png";
import ShapesIcon from "../../../../../Assets/Icons/ShapesIcon.png";
import LineIcon from "../../../../../Assets/Icons/LineIcon.png";
import TextIcon from "../../../../../Assets/Icons/TextIcon.png";
import EraserIcon from "../../../../../Assets/Icons/EraserIcon.png";
import MoveIcon from "../../../../../Assets/Icons/MoveIcon.png";
import CommentIcon from "../../../../../Assets/Icons/CommentIcon.png";
import Square from "../../../../../Assets/Icons/square.png";
import Rect from "../../../../../Assets/Icons/rectangular.png";
import circle from "../../../../../Assets/Icons/circle.png";
import triangle from "../../../../../Assets/Icons/triangle.png";
import Hexa from "../../../../../Assets/Icons/hexagon.png";
import star from "../../../../../Assets/Icons/star.png";
import MoreArrow from "../../../../../Assets/Icons/MoreDropArrow.png";
import Property from "../../../../../Assets/Icons/Property.png";




const additionalOptions = [
  { label: "Triangle", image: triangle }, // Replace "path_to_square_image" with the actual path to your square image
  { label: "Hexagon", image: Hexa }, // Replace "path_to_square_image" with the actual path to your square image
  { label: "Star", image: star }, // Replace "path_to_square_image" with the actual path to your square image


  // Add more shapes as needed
];

const shapesDropdownItems  = [
  { label: "Square", image: Square }, // Replace "path_to_square_image" with the actual path to your square image
  { label: "Circle", image: circle }, // Replace "path_to_square_image" with the actual path to your square image
  { label: "Rectangle", image: Rect }, // Replace "path_to_square_image" with the actual path to your square image
  { label: '', isMoreOption: true }
];


const emptyLabel = { label: '', isMoreOption: true };


export default function ToolSidebar({setShapeToDraw,openPropertySidebar}) {
  const [showShapesMenu, setShowShapesMenu] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [propertySidebarOpen, setPropertySidebarOpen] = useState(false);

  const shapeRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (shapeRef.current && !shapeRef.current.contains(event.target)) {
        setShowShapesMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shapeRef]);

  const toggleShapesMenu = () => {
    setShowShapesMenu(!showShapesMenu);
  };

  function handleShapeClick(shape)
  {
    console.log("Handling shape", shape);
    setShapeToDraw(shape)
  }
  function handleMoreOptionClick() {
    setShowMoreOptions(!showMoreOptions);
  }
  useEffect(() => {
    if (propertySidebarOpen) {
      document.querySelector(".property-icon").classList.add("activeLayer");
    } else {
      document.querySelector(".property-icon").classList.remove("activeLayer");
    }
  }, [propertySidebarOpen]);

  return (
    <section className="ToolSidebar">
      <div className="row">
        <div className="col-lg-12 px-1">
          <div className="iconBar">
            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="add-project-tooltip">Add Project</Tooltip>}
            >
              <a >
                <img src={AddProject} alt="Add Project" />
              </a>
            </OverlayTrigger> */}

            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="add-project-tooltip">Property Explorer</Tooltip>}
            >
              <a onClick={() => {
                openPropertySidebar();
                setPropertySidebarOpen(!propertySidebarOpen);
              }} className="property-icon cursor-pointer">
                <img src={Property} alt="Property Explorer" />
              </a>
            </OverlayTrigger>


            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="cursor-move-tooltip">Cursor Move</Tooltip>}
            >
              <a href="#">
                <img src={CursorMoveIcon} alt="Cursor Move" />
              </a>
            </OverlayTrigger> */}

            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="brush-tooltip">Brush</Tooltip>}
            >
              <a href="#">
                <img src={BrushIcon} alt="Brush" />
              </a>
            </OverlayTrigger> */}

            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="pen-tool-tooltip">Pen Tool</Tooltip>}
            >
              <a href="#">
                <img src={PenToolIcon} alt="Pen Tool" />
              </a>
            </OverlayTrigger> */}
          <div onClick={()=>handleShapeClick("FreeForm")}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="pen-tool-tooltip">Free Form</Tooltip>}
              >
              <a href="#">
                <img src={FreeDrawIcon} alt="Pen Tool" />
              </a>
            </OverlayTrigger>
              </div>

       


            {/* <div onClick={()=>handleShapeClick("square")}>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="line-tooltip">Square</Tooltip>}
                  >
                  <a href="#">
                    <img src={Square} alt="Line" />
                  </a>
                </OverlayTrigger>
              </div>

                <div onClick={()=>handleShapeClick("circle")}>
                <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="line-tooltip">Circle</Tooltip>}
            >
              <a href="#">
                <img src={circle} alt="Line" />
              </a>
            </OverlayTrigger>
                </div> */}
          
                <div onClick={()=>handleShapeClick("line")}>         
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="line-tooltip">Line</Tooltip>}
                  >
                    <a href="#">
                      <img src={LineIcon} alt="Line" />
                    </a>
                  </OverlayTrigger>
                      
                </div>
            {/* <div   onClick={()=>handleShapeClick("rect")}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="line-tooltip">Rectangular</Tooltip>}
              
              >
              <a href="#">
                <img src={Rect} alt="Line" />
              </a>
            </OverlayTrigger>
              </div> */}


         

              <div className="shape-section position-relative" ref={shapeRef}>
              <div className="shapeHead">
                <OverlayTrigger
                  overlay={<Tooltip id="pen-tool-tooltip">More Shapes</Tooltip>}
                  placement="right"
                >
                  <a onClick={toggleShapesMenu} className="cursor-pointer">
                    <img src={ShapesIcon} alt="Shapes" />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="shapeMenu">
                {showShapesMenu && (
                  <div className="dropdownContent">
                    <ul className="shapes-menu list-unstyled py-2 px-2 position-absolute top-0 start-100 z-1 bg-white rounded-2 border">
                      {shapesDropdownItems.map((shape, index) => (
                        <li
                          key={index}
                          className="py-2 w-100 d-flex align-items-center ms-auto cursor-pointer"
                          onClick={() => {
                            if (shape.isMoreOption) {
                              handleMoreOptionClick();
                            } else {
                              handleShapeClick(shape.label);
                            }
                          }}
                        >
                          {shape.isMoreOption ? 
                          <img src={MoreArrow} className="img-fluid d-flex justify-content-end m-auto"/>
                        // <i className="fa fa-ellipsis-h text-end d-flex justify-content-end ms-auto"/>
                            :
                            <img src={shape.image} alt={shape.label} className="px-2" />
                          }
                          <label className="text-black fs-6 ps-1 cursor-pointer">
                            {shape.label}
                          </label>
                        </li>
                      ))}
                      {showMoreOptions && additionalOptions.map((option, index) => (
                        <li
                          key={index}
                          className="py-2 w-100 d-flex align-items-center ms-auto cursor-pointer"
                          onClick={() => handleShapeClick(option.label)}
                        >
                          <img src={option.image} alt={option.label}  className="px-2" />
                          <label className="text-black fs-6 ps-1 cursor-pointer">
                            {option.label}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            

           <div  onClick={()=>handleShapeClick("text")}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="text-tooltip">Text</Tooltip>}
            >
              <a href="#">
                <img src={TextIcon} alt="Text" />
              </a>
            </OverlayTrigger>
               
           </div>

            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="eraser-tooltip">Eraser</Tooltip>}
            >
              <a href="#">
                <img src={EraserIcon} alt="Eraser" />
              </a>
            </OverlayTrigger> */}

            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="move-tooltip">Move</Tooltip>}
            >
              <a href="#">
                <img src={MoveIcon} alt="Move" />
              </a>
            </OverlayTrigger> */}

            {/* <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="comment-tooltip">Comment</Tooltip>}
            >
              <a href="#">
                <img src={CommentIcon} alt="Comment" />
              </a>
            </OverlayTrigger> */}
          </div>
        </div>
      </div>
    </section>
  );
}
