import React from 'react'
import './EditorMainCanvasArea.css'
import EditorCanvas from './EditorCanvas/EditorCanvas'


export default function EditorMainCanvasArea({ filePreview,zoomLevel, shapeToDraw, setShapeToDraw  }) {
  return (

      <EditorCanvas filePreview={filePreview} zoomLevel={zoomLevel} shapeToDraw={shapeToDraw} setShapeToDraw={setShapeToDraw} />

  )
}
