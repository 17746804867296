import React, { useState, useEffect, useRef } from "react";
import "./FillSettings.css";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, InputGroup } from "react-bootstrap";
// import CommonDropdown from "../../../../../../Components/CommonDropdown/CommonDropdown";
import CommonDropdown from "../../../../../../Components/CommonDropdown/CommonDropdown";
// import CommonColorPicker from "../../../../../../Components/CommonColorPicker/CommonColorPicker";
import CommonColorPicker from '../../../../../../Components/CommonColorPicker/CommonColorPicker'
import Percentage from "../../../../../../Assets/Icons/percantage.png";
import plus from "../../../../../../Assets/Icons/plus-sign.png";
import minus from "../../../../../../Assets/Icons/minus-sign.png";
import { useShapeContext } from "../../../../../../contexts/shapeContext";

const dropdownItems = [
  { label: "Solid", href: "" },
  { label: "Gradient", href: "" },
  { label: "Image", href: "" },
  { label: "Pattern", href: "" },
];
const patternDropdownItems = [
  { label: "Diagonal", href: "" },
  { label: "Horizontal", href: "" },
  { label: "Vertical", href: "" },
];
export default function FillSettings({changeProperty, fillProps }) {                                                                 
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { state, actions } = useShapeContext();
  // const [fillType, setFillType] = useState("Solid");
  // const [fillColor,setFillColor]= useState("#ffffff");
  // const [color1, setColor1] = useState("#1DB8CE");
  // const [color2, setColor2] = useState("#FFFFFF");
  // const[ fillEnabled,setFillEnabled] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null); // Define selectedItem state
  // const [selectedPattern, setSelectedPattern] = useState(null);

  const {selectedObject} = state;

  const { fillType,
    setFillType,
    fillColor,
    setFillColor,
    color1,
    setColor1,
     color2,
    setColor2, 
    fillEnabled,setFillEnabled,
    selectedPattern,setSelectedPattern,
  } = fillProps



  // const {
  //    fillColor,
  //    opacity,
  //    fillType,
  //    fillEnabled,
  //    color1,
  //    color2,
     
  //    fontStyle,
  //    fontSize,
  //    fontWeight,
  //    align,
  //    decoration,
    
  //    strokeColor,
  //    strokeWidth,
  //    strokeType,
  //    strokeOpacity,
  //   } = state.properties;

  // useEffect(()=>{
  //    setFillColor(state.properties.fillColor)
  //    setFillType(state.properties.fillType);
  //    setColor1(state.properties.color1);
  //    setColor2(state.properties.color2);
  //    setSelectedPattern(state.properties.pattern)
  // }, [state])


  useEffect(()=>{
    if(!selectedObject)
    {
      return ;
    }
    setFillTypeProperties(selectedObject)


    return (()=>{
      actions.resetShape()
    })
  }, [selectedObject])



  function  setFillTypeProperties(selectedObj) { 
      
    if (typeof selectedObj.fill === "string") {
     
  
      if (selectedObj.fill === "") {
     
        setFillColor("");
        setFillType("Solid");

      } else if(selectedObj.fill !== "") {
      
        setFillEnabled(true);
        setFillColor(selectedObj?.fill);
        setFillType("Solid");
      }
    } else {
     
      if (selectedObj?.fill?.type) {
        // console.log("Getting into gradient sectin");
        if (selectedObj?.fill?.type === "linear") {
          setFillType("Gradient");
          setColor1(
            selectedObj?.fill?.colorStops?.[0]?.color || "#ffffff"
          );
          setColor2(
            selectedObj?.fill?.colorStops?.[1]?.color || "#000000"
          );
        }
      } else if (selectedObj?.fill?.id || selectedObj?.fill?.repeat) {
            // console.log("In repeat section");
            setFillType("Pattern");
            if (selectedObj?.fill?.id === 1) {
              setSelectedPattern("Diagonal");
            } else if (selectedObj?.fill?.id === 2) {
              setSelectedPattern("Horizontal");
            } else if (selectedObj?.fill?.id === 2) {
              setSelectedPattern("Vertical");
            }
            return ;
      } else {
     
        setFillType("Solid");
      }
    }
  }


  const handleColor1Change = (newColor) => {
    // setColor1(newColor);
  };

  const handleColor2Change = (newColor) => {
    // setColor2(newColor);
  };

  const handleFillTypeChange = (selectedItem) => {
    // setSelectedItem(selectedItem); // Update selectedItem state
    // setFillType(selectedItem.label);
  };

  // Handler function for pattern change
  const handlePatternChange = (selectedItem) => {
    setSelectedPattern(selectedItem); // Update selectedPattern state
    // Handle any additional logic if needed
  };

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  // console.log("selectedObject",selectedObject);

  return (
    <div className="my-2">
      <Accordion defaultActiveKey={null} className="border-0">
        <Accordion.Item eventKey="0" className="border-0">
          <Accordion.Header
            className="border-0 text-black fs-6"
            onClick={handleAccordionToggle}
          >
            <label htmlFor="propertyName" className="propertyTextLabel">
              Fill
            </label>

            {isAccordionOpen ? (
                  <img
                  src={minus}
                  className="img-fluid d-flex ms-auto fs-5"
                  alt="Left"
                />
              
            ) : (
                <img
                src={plus}
                className="img-fluid d-flex ms-auto fs-5"
                alt="Left"
              />
              
            )}

        
          </Accordion.Header>
        {selectedObject ?   <Accordion.Body className="border-0 px-3">
            <div className="fillSettings">
              <div className="row">
                <div className="col-lg-12 px-0">
                  <CommonDropdown
                    title={fillType}
                    items={dropdownItems}
                    selectedItem={selectedItem}
                    handleSelect={(e)=>{
                      setFillType(e.label)
                      changeProperty("fillType", e.label)}
                    }
                  />{" "}
                  {/* Pass selectedItem and handleSelect */}
                </div>
                <div className="row px-0 mx-0 my-2 ">
                  {fillType === "Solid" && (
                    <div className="row px-0 mx-0 mb-0">
                      {/* Hidden color picker */}
                      <div className="ColorPicker col-lg-7 ps-0">
                        <CommonColorPicker
                          value={fillColor}
                          onChange={(e)=> { 
                            setFillColor(e);
                            changeProperty("fillColor", e)}
                          }
                        />
                      </div>
                      <div className="col-lg-5 pe-0">
                        <InputGroup className="pe-0 border-0">
                          <Form.Control
                            id="inlineFormInputGroup"
                            className="propertyTextBox border-end border-start-0 border-top-0 border-bottom-0"
                            placeholder="10"
                          />
                          <InputGroup.Text className="propertyTextBox border-end-0 border-start-0 border-top-0 border-bottom-0">
                            <img src={Percentage} className="img-fluid" />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                  )}

                  {fillType === "Gradient" && (
                    <div className="row px-0 mx-0 my-2 mb-0">
                      {/* Hidden color picker */}
                      <div className="ColorPicker col-lg-4 px-1">
                        <CommonColorPicker
                          value={color1}
                          onChange={(e)=> { 
                            setColor1(e);
                            changeProperty("color1", e)}
                          }
                        />
                      </div>
                      <div className="ColorPicker col-lg-4 px-1">
                        <CommonColorPicker
                          value={color2}
                          onChange={(e)=>{ 
                            setColor2(e)
                            changeProperty("color2", e)}
                          }
                        />
                      </div>
                      <div className="col-lg-4 px-1">
                        <InputGroup className="pe-0 border-0">
                          <Form.Control
                            id="inlineFormInputGroup"
                            className="propertyTextBox border-end border-start-0 border-top-0 border-bottom-0"
                            placeholder="10"
                          />
                          <InputGroup.Text className="propertyTextBox border-end-0 border-start-0 border-top-0 border-bottom-0">
                            <img src={Percentage} className="img-fluid" />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                  )}
                  {fillType === "Image" && <div>Image</div>}
                  {fillType === "Pattern" && (
                    <div className="row px-0 mx-0">
                      {/* CommonDropdown for Pattern */}
                      <div className="col-lg-12 px-0">
                        <CommonDropdown
                          title={
                            selectedPattern
                              || selectedPattern.label
                              || "Select Pattern"
                          }
                          items={patternDropdownItems}
                          selectedItem={selectedPattern.label}
                          // handleSelect={handlePatternChange}
                          defaultActiveKey={1}
                          handleSelect={(e)=>{
                            setSelectedPattern(e.label)
                            changeProperty("pattern", e.label)
                          }
                          }

                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="row mx-0 px-0"></div>
              </div>
            </div>
          </Accordion.Body>
          :
          <Accordion.Body className="border-0 px-3">
          <div className="fillSettings">
             <span className="text-muted small-font-size">No Shape Selected</span>
          </div>
        </Accordion.Body>
         }
          
           
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
