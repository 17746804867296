import React, { useState } from "react";
import LandingHeader from "./LandingHeader/LandingHeader";
import LandingSidebar from "./LandingSidebar/LandingSidebar";
import LandingContentArea from "./LandingContentArea/LandingContentArea";

export default function LandingLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false); // State for theme

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`layout-container ${sidebarOpen ? "sidebar-open" : ""}`}>
      <LandingHeader
        toggleSidebar={toggleSidebar}
        darkMode={darkMode}
        toggleTheme={toggleTheme}
      />
      <div className="content-wrapper">
        <LandingSidebar
          sidebarOpen={sidebarOpen}
          darkMode={darkMode}
          toggleTheme={toggleTheme}
        />
        <LandingContentArea
          sidebarOpen={sidebarOpen}
          darkMode={darkMode}
          toggleTheme={toggleTheme}
        />
      </div>
    </div>
  );
}
