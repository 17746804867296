import React, { useState } from 'react';
import Calendar from 'react-calendar';
import './CustomCalendar.css'
import 'react-calendar/dist/Calendar.css';

const CustomCalendar = () => {
  const [date, setDate] = useState(new Date());

  const handlePrevClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    setDate(newDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    setDate(newDate);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <button onClick={handlePrevClick} className="border-0">
        <i className="fs-1 fa fa-angle-left" />
      </button>
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        {[0, 1, 2, 3, 4].map((index) => (
          <div key={index} style={{ marginRight: "10px" }}>
          <Calendar
            key={index}
            value={new Date(date.getFullYear(), date.getMonth() + index, 1)}
            tileDisabled={({ date }) =>
              date.getMonth() < date.getMonth() + index || date.getMonth() > date.getMonth() + index
            }
            nextLabel={false}
            prevLabel={false}
            prev2Label={false}
            next2Label={false}
          />
          </div>
        ))}
      </div>
      <button onClick={handleNextClick} className="border-0">
        <i className="fs-1 fa fa-angle-right" />
      </button>
    </div>
  );
};

export default CustomCalendar;
