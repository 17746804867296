export const defaultRectShapeValue = {
    left: 100,
    top: 100,
    width: 120,
    height: 80,
    fill: "",
    stroke: "#000000",
    strokeWidth: 2,
    strokeDashArray: null,
    strokeUniform: true,
    visible: true, // Initially visible
  };
  export const defaultSquareShapeValue = {
    left: 200,
    top: 200,
    width: 80,
    height: 80,
    fill: "",
    stroke: "#000000",
    strokeWidth: 2,
    strokeDashArray: null,
    strokeUniform: true,
    visible: true, // Initially visible
    lockScalingX: false, // Lock scaling on the X-axis
    lockScalingY: false, // Lock scaling on the Y-axis
    lockUniScaling: false, // Lock uniform scaling
  };
  
  export const defaultCircleShapeValue = {
    left: 200,
    top: 200,
    radius: 40,
    fill: "",
    stroke: "#000",
    strokeWidth: 2,
    strokeDashArray: null,
    strokeUniform: true,
    visible: true, // Initially visible
  };

  export const defaultLineValue = {
    x1: 50,
    y1: 50,
    x2: 150,
    y2: 150,
    stroke: "#000000",
    strokeWidth: 2,
    strokeDashArray: null,
    visible: true, // Initially visible
   
  };


 export const defaultTextValue = {
    left: 300,
    top: 300,
    fontSize: 20,
    fontFamily: "Arial",
    strokeWidth: 0,
    stroke: "#000",
    fill: "#333",
    strokeDashArray: null,
    strokeUniform: true,
    visible: true, // Initially visible
  };

  export const defaultTriangleShapeValue = {
    left: 300,
    top: 200,
    width: 80,
    height: 80,
    fill: "",
    stroke: "#000",
    strokeWidth: 2,
    strokeDashArray: null,
    strokeUniform: true,
    visible: true, // Initially visible
  };

  export const defaultStarShapeValue = {
 
    left: 100,
    top: 100,
    fill: "",
    stroke: "#000000",
    strokeWidth: 2,
    strokeUniform: true,
    strokeDashArray: null,
    visible: true, // Initially visible
  };

  export const defaultOctagonShapeValue = {
    left: 100,
    top: 100,
    fill: "",
    stroke: "#000000",
    strokeWidth: 2,
    strokeUniform: true,
    strokeDashArray: null,
    visible: true, // Initially visible
  };

  export const defaultHexagonShapeValue = {
    left: 100,
    top: 100,
    fill: '',
    stroke: '#000000',
    strokeWidth: 2,
    strokeUniform: true,
    strokeDashArray: null,
    visible: true,
  };