import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Data from "../../fakeData/Data/Data";
import { Modal } from "react-bootstrap";
import Logo from '../../Assets/Images/Logo.png'
import "./Login.css";

const Login = ({
  show,
  handleClose,
  title,
  body,
  onClose,
  onSave,
  darkMode,
  handleShowSignupModal,
}) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    fullname: "",
    isUserLogin: false,
    isAdminLogin: false,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = formData;

    let isUserExist = Data.find((user) => user.username === username);

    if (isUserExist) {
      if (password === isUserExist.password && isUserExist.role === "Admin") {
        localStorage.setItem(
          "AdminToken",
          "AsiaToJapanAdminhbchsdbchsdbjcxjcbdshjoeuwyru"
        );
        setFormData({
          ...formData,
          isAdminLogin: true,
          fullname: isUserExist.firstName + " " + isUserExist.lastName,
        });
      } else if (
        password === isUserExist.password &&
        isUserExist.role === "User"
      ) {
      } else {
        alert("Wrong Password");
      }
    } else {
      alert("User Not exist");
    }
  };

  if (formData.isAdminLogin) {
    return (
      <Redirect
        to={{
          pathname: "/dashboard",
          state: { fullname: formData.fullname },
        }}
      />
    );
  } else if (formData.isUserLogin) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { fullname: formData.fullname },
        }}
      />
    );
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Body
          style={{
            backgroundColor: darkMode
              ? "var(--background-color-dark)"
              : "var(--background-color-light)",
          }}
  
        >
          <div
            className={`fa fa-close d-flex fs-5 ms-auto m-3 mt-0 me-0 cursor-pointer justify-content-end menuTextHeader ${
              darkMode ? "dark-mode" : ""
            }`}
            onClick={onClose}
          />

          <div className="row">
            <div className="col-lg-12 py-3 pt-0">
              <div className="loginSectionDiv d-flex flex-column align-items-center justify-content-center m-auto">
                <div className="headerLogin">
                  <h1
                    className={`logoText loginTextColor mb-0 ${
                      darkMode ? "dark-mode" : ""
                    }`}
                  >
                    <img src={Logo} className="img-fluid" />
                  </h1>
                </div>
                <div className="text-center py-3">
                  <h3
                    className={`my-1 fw-bold menuTextHeader ${
                      darkMode ? "dark-mode" : ""
                    }`}
                  >
                    Sign in to your account
                  </h3>
                  <h5
                    className={`fw-normal my-3 menuTextHeader ${
                      darkMode ? "dark-mode" : ""
                    }`}
                  >
                    Start your demo version
                  </h5>
                </div>

                <div className="col-lg-10">
                  <form className="px-0 mx-0 " onSubmit={handleSubmit}>
                    <div className="form-group my-3 mt-0">
                      <label
                        for="exampleInputPassword1"
                        className={`py-2 yellowButtonColor ${
                          darkMode ? "dark-mode" : ""
                        }`}
                      >
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control formLoginControl"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group my-3">
                      <label
                        for="exampleInputPassword1"
                        className={`py-2 yellowButtonColor ${
                          darkMode ? "dark-mode" : ""
                        }`}
                      >
                        Password*
                      </label>
                      <input
                        type="password"
                        className="form-control formLoginControl"
                        id="exampleInputPassword1"
                        name="password"
                        value={formData.password}
                        placeholder="Password"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className={`form-check-label lightText ${
                            darkMode ? "dark-mode" : ""
                          }`}
                        >
                          Remember Me
                        </label>
                      </div>
                      <label
                        className={`form-check-label yellowButtonColor ${
                          darkMode ? "dark-mode" : ""
                        }`}
                      >
                        Forgot Password ?
                      </label>
                    </div>

                    <button className="w-100 my-4 rounded-2 btn btn-primary"  type="submit">
                      Sign In
                    </button>
                    <div className="text-center d-flex justify-content-center">
                      <div
                        className={`menuTextHeader ${
                          darkMode ? "dark-mode" : ""
                        }`}
                      >
                        Don’t have an account?
                      </div>
                      <label
                        className={`px-2 loginTextColor form-check-label  cursor-pointer  yellowButtonColor ${
                          darkMode ? "dark-mode" : ""
                        }`}
                        onClick={handleShowSignupModal}
                      >
                        Signup
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <form onSubmit={handleSubmit} className="d-none">
        <div>
          <input
            type="text"
            name="username"
            value={formData.username}
            placeholder="Enter Username"
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <input
            type="password"
            name="password"
            value={formData.password}
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </div>
        <br />
        <div>
          <button type="submit">Login</button>
        </div>
      </form>

      <div className="d-none">
        <h3>Admin Credentials:</h3>
        <span>
          username:- <strong>admin@test.com</strong>
        </span>
        <br />
        <span>
          password:- <strong>admin</strong>
        </span>
      </div>
    </>
  );
};

export default Login;
