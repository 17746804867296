import React, { useState, useEffect, useRef } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { fabric } from 'fabric';
import "./EditorCanvas.css";
import { pdfjs } from 'react-pdf';


import { useShapeContext } from '../../../../../contexts/shapeContext';
import {
    defaultCircleShapeValue,
    defaultLineValue,
    defaultRectShapeValue,
    defaultSquareShapeValue,
    defaultTextValue,
    defaultTriangleShapeValue,
    defaultStarShapeValue,
    defaultOctagonShapeValue,
    defaultHexagonShapeValue,
  } from "../../../../../Helpers/defaultShapes/defaultShapes";

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


  function Editor({filePreview,zoomLevel, shapeToDraw, setShapeToDraw,}) {
  const [selectedIdx,setSelectedIdx]= useState();
//   const [canvases, setCanvases] = useState([{ id: 'canvas-1' }]);
// const [shapes,setShapes]= useState;
//   const [activeCanvas, setActiveCanvas] = useState('canvas-1');
const [shapes,setShapes] = useState();
  const { state, actions } = useShapeContext();
  const {
          canvasData,
          users,
          currentUser,
          selectedObject,
          commentText,
          commentAddEditFlag,
          titleText,
          canvases,
          activeCanvas,
          currentOption,
          currentObj
        } = state;
  
        useEffect(()=>{
          if(!currentOption || !currentObj)
          {
            return ;
          }

          switch (currentOption) {
            case 1:
              duplicateShape(currentObj)
              break;

              case 2:
                removeShape(currentObj)
                break;
          
            default:
              break;
          }

          actions.updateCurrentOpt({currentOption: null, currentObj:null  })

        }, [currentOption])

        useEffect(()=>{
          const currCanvasData = canvasData.canvasShapes.filter((canvasShape)=> canvasShape.canvasId===activeCanvas);
          // console.log("currCanvasData", currCanvasData); 
          if(currCanvasData.length>0)
          {
            setShapes(currCanvasData[0].shapes)
          }

        }, [activeCanvas,canvasData])

        
  const canvasRefs = useRef({});

  useEffect(()=>{

    if(!canvasRefs)
    {
     return ;
    }
    // console.log("Changeddd");
    canvasRefs.current[activeCanvas]?.requestRenderAll();
 }, [state])

 useEffect(() => {
  const loadImage = async () => {
    try {
      if (!filePreview) return;
      if (filePreview.type.startsWith("image/")) {
        const imageUrl = await loadImageAsDataURL(filePreview);
        setBackgroundImage(imageUrl);
      } else if (filePreview.type === "application/pdf") {
        const imageUrl = await renderPdfAsImage(filePreview);
        setBackgroundImage(imageUrl);
      }
    } catch (error) {
      console.error("Error handling image upload:", error);
    }
  };

  loadImage();
}, [filePreview]);



const handleImageUpload = async (event) => {
  const file = event.target.files[0];




};

const renderPdfAsImage = async (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
          try {
              const typedArray = new Uint8Array(event.target.result);
              const pdfData = typedArray.buffer;

              const pdfDoc = await pdfjs.getDocument({ data: pdfData }).promise;
              const pdfPage = await pdfDoc.getPage(1);

              const viewport = pdfPage.getViewport({ scale: 1 });
              const canvasElement = document.createElement("canvas");
              const context = canvasElement.getContext("2d");
              canvasElement.width = viewport.width;
              canvasElement.height = viewport.height;

              await pdfPage.render({ canvasContext: context, viewport }).promise;

              const imageUrl = canvasElement.toDataURL("image/jpeg");
              resolve(imageUrl);
          } catch (error) {
              reject(error);
          }
      };
      reader.readAsArrayBuffer(file);
  });
};

const loadImageAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
  });
};



const setBackgroundImage = (imageUrl) => {
  const canvas = canvasRefs.current[activeCanvas];
  fabric.Image.fromURL(imageUrl, (img) => {
      img.scaleToWidth(canvas.width);
      img.scaleToHeight(canvas.height);
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
  });
};




  const initializeFabricCanvas = (canvasId) => {
    const canvas = new fabric.Canvas(canvasId, {
        backgroundColor: "gray",
    });
    // canvas.isDrawingMode = true; // Enable drawing mode
    canvas.originalWidth = window.innerWidth;
    canvas.originalHeight = window.innerHeight;
   
    canvas.on("after:render", () => {
        canvas.calcOffset();
        canvas.forEachObject((obj) => obj.setCoords());
      });
     

    return canvas;
  };

  useEffect(() => {
    canvases.forEach((canvas) => {
      if (!canvasRefs.current[canvas.id]) {
        canvasRefs.current[canvas.id] = initializeFabricCanvas(canvas.id);
        zoom(1);
        canvasRefs.current[canvas.id].on("after:render", () => {
            canvasRefs.current[canvas.id].calcOffset();
            canvasRefs.current[canvas.id].forEachObject((obj) => obj.setCoords());
          });
      }


    });


  }, [canvases]);

  useEffect(() => {
    if ( canvasRefs &&  canvasRefs.current[activeCanvas]) {
      zoom(zoomLevel);
    }
  }, [zoomLevel]);
  const zoom = (scale) => {
    const container = document.querySelector('.editor-canvas-container');
    const canvasWidth = canvasRefs.current[activeCanvas].originalWidth * scale;
    const canvasHeight =canvasRefs.current[activeCanvas].originalHeight * scale;

    // Update canvas size
    canvasRefs.current[activeCanvas].setWidth(canvasWidth);
    canvasRefs.current[activeCanvas].setHeight(canvasHeight);

    // Toggle overflow based on zoom level
    if (scale !== 1) {
        container.style.overflow = 'auto'; // Show scrollbar when zoomed
    } else {
        container.style.overflow = 'hidden'; // Hide scrollbar when at default zoom level
    }

    canvasRefs.current[activeCanvas].setZoom(scale);
    canvasRefs.current[activeCanvas].renderAll();
};

useEffect(() => {
    if (canvasRefs && canvasRefs.current[activeCanvas]) {
      if (shapeToDraw !== "") {
        createShape(shapeToDraw);
      }
    }
  }, [shapeToDraw]);

  useEffect(()=>{
    if(!canvasRefs)
    {
     return ;
    }
    if(commentAddEditFlag === "")
    {
     return ;
    }

  
    if(commentAddEditFlag==="comment")
    {
       addComments();
    }
    else if(commentAddEditFlag==="title")
    {
       addTitle();
    }
 }, [commentAddEditFlag])

 function addTitle()
 {
 
    if(titleText !== "" )
    {
    
         const isExistingTitle = isExistingProp('title');
         if(isExistingTitle !== null)
         {
       
           isExistingTitle.set({
             text: titleText
           })

         }
         else{
            
               const title = new fabric.IText(titleText, {
                         left: selectedObject.left + 20,
                         top: selectedObject.top - 30,
                         fontSize: 14,
                         fill: "#333",
                         width: 150,
                         height: "auto",
                         padding: 10,
                         selectable: true,
                         lockMovementX: true,
                         lockMovementY: true,
                         fontFamily: "Arial",
                         fontWeight: "bold",
                         fontStyle: "normal",
                         textAlign: "left",
                         visible: true,
                       });
                       canvasRefs.current[activeCanvas].add(title);
                 const payload={
                   label: 'title',
                   selectedShape: selectedObject,
                   item: title
                 };
                 actions.updateShape(payload)
         }

       
        actions.updateCommentAddEditFlag(false)
        // actions.updateCommentText("")
            // canvas.current.requestRenderAll()

    }
 }

 function addComments()
 {
    // console.log("Commednt related data", commentText, commentAddEditFlag, selectedObject);
    if(commentText !== "" )
    {
        //  console.log("Comment text is ", commentText, selectedObject);
         const isExistingComment = isExistingProp('comment');
         if(isExistingComment !== null)
         {
          //  console.log("ITS not nukll",isExistingComment );
           isExistingComment.set({
             text: commentText
           })

         }
         else{
              //  console.log("NULL ",isExistingComment);
               const comment = new fabric.IText(commentText, {
                         left: selectedObject.left + 50,
                         top: selectedObject.top -20,
                         fontSize: 14,
                         fill: "#333",
                         width: 150,
                         height: "auto",
                         padding: 10,
                         selectable: true,
                         lockMovementX: true,
                         lockMovementY: true,
                         fontFamily: "Arial",
                         fontWeight: "normal",
                         fontStyle: "normal",
                         textAlign: "left",
                         visible: true,
                       });
                       canvasRefs.current[activeCanvas].add(comment);
                 const payload={
                   label: 'comment',
                   selectedShape: selectedObject,
                   item: comment
                 };
                 actions.updateShape(payload)
         }

       
        actions.updateCommentAddEditFlag("")
        // actions.updateCommentText("")
            // canvas.current.requestRenderAll()

    }
 }

 function isExistingProp(label)
 {
   switch (label) {
     case 'comment':
       const alreadyExistIndex =  shapes.findIndex(
         (shape) => shape.shape === selectedObject
       );
       if (alreadyExistIndex !== -1) {
         return shapes[alreadyExistIndex].comment;
       } else {
         return null;
       }
           break; 

     case 'title':
         const alreadyExistIndexTitle =  shapes.findIndex(
           (shape) => shape.shape === selectedObject
         );
         if (alreadyExistIndexTitle !== -1) {
           return shapes[alreadyExistIndexTitle].title;
         } else {
           return null;
         }
         break; 
       
       
   
     default:
       break;
   }
 }

  function createShape(shape) {
    // console.log("Shape drawing is ", shape);
    switch (shape) {
      case "Rectangle":
        addRectangle();
        break;
      case "Circle":
        addCircle();
        break;
      case "Square":
        addSquare();
        break;
      case "line":
        addLine();
        break;
      case "text":
        addText();
        break;
      case "Triangle":
        addTriangle();
        break;
      case "Star":
        addStar();
        break;
      case "Hexagon":
        addHexagon();
        break;
      case "FreeForm":
        // toggleDrawPolygon();
        break;

      default:
        break;
    }
    setShapeToDraw("")
  }
  function addShape(shape) {
    // console.log("SHAPE", shape,);
    // actions.resetShape()
    if (!canvasRefs) return;
    if (canvasRefs.isDrawingMode) {
        canvasRefs.isDrawingMode = false;
    }

    // console.log("Drawing shape is ", shape);



    canvasRefs.current[activeCanvas].add(shape);
    const newShape = {
      canvasId: activeCanvas,
      icon: null,
      comment: null,
      title: null,
      shape: shape,
    };
    const userSpecificShape = {
      currentUser: currentUser,
      shape: shape,
      canvasId: activeCanvas
    };
    const payload={newShape};
    actions.addShape(newShape);
  
    actions.addUserShapes(userSpecificShape);
    canvasRefs.current[activeCanvas].setActiveObject(shape);
    //  setShapes([...shapes, newShape]);
    // setShapeVisibility([...shapeVisibility, true]); // Initialize visibility state for the shape
  }



  const handleObjectModified = (e) => {
    const modifiedObject = e.target;
    const modifiedObjectIndex = shapes.findIndex(
      (shape) => shape.shape === modifiedObject
    );
    //  console.log("Movinf this ", modifiedObjectIndex, shapes, modifiedObject );
    // Your logic for handling the modification of the shape
  
  

    if (modifiedObjectIndex !== -1) {
    
     if(shapes[modifiedObjectIndex].comment !== null){
      shapes[modifiedObjectIndex].comment.set({
        top: modifiedObject.top-30,
        left: modifiedObject.left+80,
      })
    }
    if(shapes[modifiedObjectIndex].title !== null){
      shapes[modifiedObjectIndex].title.set({
        top: modifiedObject.top,
        left: modifiedObject.left,
      })
      // canvas.current.requestRenderAll()
    }
   
    }

    // if (modifiedShapeEmojiIdx !== -1) {
    //   updateIconPosition(
    //     modifiedShapeEmojiIdx,
    //     shapeEmojis,
    //     modifiedObject.top,
    //     modifiedObject.left,
    //     modifiedObject
    //   );
    // }

    // if (modifiedShapeTitleIdx !== -1) {
    //   updateShapeTitlePosition(
    //     modifiedShapeTitleIdx,
    //     shapeTitles,
    //     modifiedObject.top,
    //     modifiedObject.left,
    //     modifiedObject
    //   );
    // }
  };

  useEffect(() => {
    if (canvasRefs) {
      // Event listener for selection creation
      canvasRefs.current[activeCanvas].on("selection:created", handleSelection);
      // Event listener for selection update (e.g., when selecting a different shape)
      canvasRefs.current[activeCanvas].on("selection:updated", handleSelection);
      // Event listener for selection clear
      canvasRefs.current[activeCanvas].on("selection:cleared", handleSelectionClear);
      // canvas.on("mouse:down", handleRightClickOnShape);
      // canvas.on("mouse:down", preventDefaultContextMenu);
      canvasRefs.current[activeCanvas].on("object:modified", handleObjectModified);


    
     
    }

    return () => {
      if (canvasRefs) {
        canvasRefs.current[activeCanvas].off("selection:created", handleSelection);
        canvasRefs.current[activeCanvas].off("selection:updated", handleSelection);
        canvasRefs.current[activeCanvas].off("selection:cleared", handleSelectionClear);
        // canvas.off("mouse:down" , onMouseDown);
        // canvas.off("mouse:down", handleRightClickOnShape);
        canvasRefs.current[activeCanvas].off("object:modified", handleObjectModified);

      }
    };
  }, [canvasRefs, shapes ]);


  const handleSelection = (e) => {

    const selectedObjectCurrent = e.selected;
   if(selectedObjectCurrent.length === 1)
   {
     const index = shapes.findIndex((shape) => shape.shape === selectedObjectCurrent[0]);
     console.log("Selected hspe index is ", index, selectedObjectCurrent);
     setSelectedIdx(index);
    const payload={
      selectedObj: selectedObjectCurrent[0] ,
      selectedObjIdx: index
    }
     actions.updateCurrentObj(payload)
   }
    // console.log("Selected hspe index is ", index, shapes, e);
    // setSelectedIdx(index);
  
    // setIsShapeSelected(true);

    // highlightShapeRelated(selectedObject)

    // setFillTypeProperties(selectedObject);
    // setStrokeColor(selectedObject?.stroke || "#000");
    // setStrokeWidth(selectedObject.strokeWidth || "2");
    // setStrokeProperties(selectedObject);
    // setOutlineColor(selectedObject?.stroke || "#000");
    // setFillEnabled(selectedObject?.fill || false);
    // if(selectedObject?.text)
    // {
    //    handleTextProperties(selectedObject)
    // }

  };


  const handleSelectionClear = () => {
    actions.updateCurrentObj(null);
    // removeHighlight(shapes[selectedShapeIndex])
    // setSelectedShapeIndex(null);
    // setIsShapeSelected(false);


    // // Hide the comment text when no shape is selected
    // setComment("");
  };


  const duplicateShape = (shape) => {
    if (!canvasRefs) return;
    // console.log("Duplicate calledd");
    if (canvasRefs.isDrawingMode) {
      canvasRefs.isDrawingMode = false;
    }

    fabric.util.enlivenObjects([shape.toObject()], (objects) => {
      
      const clonedShape = objects[0];
      if (!clonedShape) return;

      clonedShape.set({
        left: shape.left + 20,
        top: shape.top + 20,
      });

      canvasRefs.current[activeCanvas].add(clonedShape);
 
       addShape(clonedShape)

     


      canvasRefs.current[activeCanvas].renderAll();


    });
  };
  const removeShape = (shape) => {
    if (!canvasRefs) return;
    canvasRefs.current[activeCanvas].remove(shape);
    // setShapes(shapes.filter((item) => item !== shape));
    const payload = {
      shapeId : shape,
      canvasId2 : activeCanvas
    }
    actions.removeShape(payload)
    const userShapePayload={
      shapeId2 : shape,
      canvasId4 : activeCanvas,
      userId: currentUser
    }

    actions.removeUserShape(userShapePayload)


 
  
  };


  const addRectangle = () => {
    const rect = new fabric.Rect({
      ...defaultRectShapeValue,
    });
    addShape(rect);
  };

  const addCircle = () => {
    // const fillValue = fillType === "image" ? createImageFill() : getFillValue();
    const circle = new fabric.Circle({
      ...defaultCircleShapeValue,
    });
    addShape(circle);
  };
  const addSquare = () => {
    const square = new fabric.Rect({
      ...defaultSquareShapeValue,
    });

    addShape(square);
  };
  const addLine = () => {
    const line = new fabric.Line(
      [
        defaultLineValue.x1,
        defaultLineValue.y1,
        defaultLineValue.x2,
        defaultLineValue.y2,
      ],
      {
        ...defaultLineValue,
      }
    );

    addShape(line);
  };
  const addText = () => {
    const text = new fabric.IText("Enter text here", {
      ...defaultTextValue,
    });
    addShape(text);
  };
  const addTriangle = () => {
    const triangle = new fabric.Triangle({
      ...defaultTriangleShapeValue,
    });
    addShape(triangle);
  };
  const addStar = () => {
    const starPoints = [
      { x: 349.9, y: 75 },
      { x: 379, y: 160.9 },
      { x: 469, y: 160.9 },
      { x: 397, y: 214.9 },
      { x: 423, y: 300.9 },
      { x: 350, y: 249.9 },
      { x: 276.9, y: 301 },
      { x: 303, y: 215 },
      { x: 231, y: 161 },
      { x: 321, y: 161 },
    ];

    const star = new fabric.Polygon(starPoints, {
      ...defaultStarShapeValue,
    });

    addShape(star);
  };
  const addOctagon = () => {
    const octagonPoints = [
      { x: -37.282, y: 90 },
      { x: 37.282, y: 90 },
      { x: 90, y: 37.282 },
      { x: 90, y: -37.282 },
      { x: 37.282, y: -90 },
      { x: -37.282, y: -90 },
      { x: -90, y: -37.282 },
      { x: -90, y: 37.282 },
    ];

    const octagon = new fabric.Polygon(octagonPoints, {
      ...defaultOctagonShapeValue,
    });

    addShape(octagon);
  }; 

  const addHexagon = () => {
    const hexagonPoints = [
      { x: 0, y: -100 },
      { x: 86.60254037844385, y: -50 },
      { x: 86.60254037844385, y: 50 },
      { x: 0, y: 100 },
      { x: -86.60254037844385, y: 50 },
      { x: -86.60254037844385, y: -50 },
    ];

    const hexagon = new fabric.Polygon(hexagonPoints, {
      ...defaultHexagonShapeValue,
    });

    addShape(hexagon);
  };



  return (
    <div>
  
      {canvases.map((canvas) => (
        <div key={canvas.id} style={{ display: canvas.id === activeCanvas ? 'block' : 'none' }} className="editor-canvas-container" > 
          <canvas id={canvas.id} width={400} height={400}></canvas>
        </div>
      ))}
    </div>
  );
}

export default Editor;