import React, { useEffect, useState } from "react";
import toggleFooterBtn from "../../../../Assets/Icons/arrow-up-double-sharp.png";
import { Nav, Dropdown } from "react-bootstrap"; // Import Dropdown from react-bootstrap
import plus from "../../../../Assets/Icons/plus-sign.png";

import "./EditorFooter.css";
import CustomCalendar from "./CustomCalender/CustomCalender";
import { useShapeContext } from "../../../../contexts/shapeContext";

const EditorFooter = ({ onZoomChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { state, actions } = useShapeContext();
  const { canvases, activeCanvas } = state;
  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility

  // Function to handle right-click event
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  console.log("SATEATE", state);

  useEffect(() => {
    if (canvases.length > 0 && !activeCanvas) {
      actions.updateActiveCanvas(canvases[0].id);
    }
  }, [canvases, activeCanvas, actions]);

  const toggleFooter = () => {
    setIsExpanded(!isExpanded);
  };

  const handleZoomChange = (e) => {
    const newZoomLevel = parseFloat(e.target.value);
    onZoomChange(newZoomLevel); // Call the onZoomChange function passed from props
  };

  const addCanvas = () => {
    const newCanvasId = `canvas-${canvases.length + 1}`;
    actions.addCanvas(newCanvasId);
    actions.updateActiveCanvas(newCanvasId); // Set the newly added canvas as active
  };

  const handleCanvasSelect = (canvasId) => {
    actions.updateActiveCanvas(canvasId);
  };

  const duplicateCanvas = (canvasId) => {
    const newCanvasId = `canvas-${canvases.length + 1}`;
    const canvasToDuplicate = canvases.find((canvas) => canvas.id === canvasId);
    if (canvasToDuplicate) {
      actions.addCanvas(newCanvasId, canvasToDuplicate.shapes);
    }
  };

  const deleteCanvas = (canvasId) => {
    actions.deleteCanvas(canvasId);
  };

  return (
    <div id="footer" style={{ height: isExpanded ? "400px" : "40px" }}>
      <div className="row justify-content-between">
        {/* Show these elements only when the footer is expanded */}
        {!isExpanded && (
          <>
            <div className="col-lg-6 ps-5">
              <Nav
                className="ps-5 rounded-0"
                variant="tabs"
                defaultActiveKey="link-1"
              >
                <Nav.Item>
                  <Nav.Link onClick={addCanvas}>
                    <img src={plus} className="img-fluid" />
                  </Nav.Link>
                </Nav.Item>
                {canvases.map((canvas, index) => (
                  <Nav.Item key={index}>
                    {/* Use Dropdown component */}
                    <Dropdown
                    className=""
                        show={showDropdown}
                        align="end" // Align the dropdown to the end (right)
                        onClick={toggleDropdown} // Toggle dropdown on click
                    >
              <Dropdown.Toggle
                        as={Nav.Link}
                        id={`dropdown-link-${index + 1}`}
                        onClick={() => handleCanvasSelect(canvas.id)}
                        active={activeCanvas === canvas.id}
                      >
                        Page {index + 1}
                        <i className="fa fa-angle-down ps-2" />
                      </Dropdown.Toggle>
                      {/* <Dropdown.Menu className="start-50">
                        <Dropdown.Item
                          onClick={() => duplicateCanvas(canvas.id)}
                        >
                          Duplicate Page
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteCanvas(canvas.id)}>
                          Delete Page
                        </Dropdown.Item>
                      </Dropdown.Menu> */}
                    </Dropdown>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
            <div className="col-lg-6">
              <input
                className="pt-2 w-25 d-flex ms-auto me-3"
                type="range"
                min="0.1"
                max="2"
                step="0.05"
                onChange={handleZoomChange}
                defaultValue="1"
              />
            </div>
          </>
        )}
      </div>
      {/* Toggle button for expanding/collapsing the footer */}
      <div
        id="footerbuttondown"
        style={{ visibility: isExpanded ? "visible" : "hidden" }}
        onClick={toggleFooter}
      >
        <img src={toggleFooterBtn} height={20} width={20} alt="ss" />
      </div>
      <div
        id="footerbuttonup"
        style={{ visibility: isExpanded ? "hidden" : "visible" }}
        onClick={toggleFooter}
      >
        <img src={toggleFooterBtn} height={20} width={20} alt="ss" />
      </div>
      {/* Content to show when the footer is expanded */}
      <div
        id="footercont"
        style={{
          opacity: isExpanded ? 1 : 0,
          visibility: isExpanded ? "visible" : "hidden",
        }}
      >
        <div className="container-fluid">
          <div className="row px-5">
            <div className="col-lg-12 px-5">
              <CustomCalendar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorFooter;
