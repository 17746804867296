import React, { useState } from 'react'
import threeDot from  '../../../../Assets/Icons/three-dots-svgrepo-com.svg'
import { useShapeContext } from '../../../../contexts/shapeContext';

const Layer = ({userShape, getImgType, setCurrentActiveOpt, currActiveOpt, index}) => {
const [showOpt,setShowOpt] = useState(false);
const { state, actions } = useShapeContext();



function handleLayerOptionClick()
{
//     console.log("Click", index, currActiveOpt);
  setCurrentActiveOpt(index)
  if(index===currActiveOpt){
  setShowOpt((prev) => !prev)
  }
  else{
    setShowOpt(true)
  }

}
console.log("sdfsdf", showOpt);

function handleOptions(type)
{
     switch (type) {
        case 'DUPLICATE':
            actions.updateCurrentOpt({currentOption: 1, currentObj:userShape  })
            break;
            case 'DELETE':
                actions.updateCurrentOpt({currentOption: 2, currentObj:userShape  })
                break;
     
        default:
            break;
     }
     handleLayerRemove()
}



function handleLayerRemove()
{
//   removeShape(shape)
  setShowOpt(false)
   setCurrentActiveOpt(null) 
}


  return (
    <div className='position-relative'>
                              <div className="d-flex flex-row justify-content-between align-items-center  ">
                               
                               <div className="iconText w-auto  me-auto ps-4 py-2 pt-0">
                                 <img
                                   src={getImgType(userShape)}
                                   className="img-fluid"
                                   alt="shape-img"
                                   width={30}
                                 />
                                 <label className="ps-2">
                                   {userShape?.type}
                                 </label>
                               </div>
                               <img
                                   src={threeDot}
                                   className="img-fluid cursor-pointer"
                                   alt="three-dot"
                                   width={20}
                                   onClick={handleLayerOptionClick}
                                 />
                               </div>


                               {currActiveOpt === index && showOpt  && (
                               <div className="d-flex flex-column fs-6 border p-2  shadow position-absolute end-0 top-100 z-2 rounded-xl bg-white pointer text-start">
  <p  className="hover-layer my-1 mx-2 cursor-pointer " onClick={()=>handleOptions('DELETE' )}>
    Delete
  </p>
  {/* <button className="bg-transparent border-0 me-auto ps-0" onClick={() => toggleShapeVisibility(index)}>
      {shapeVisibility[index] ? "Hide" : "Show"}
  </button> */}
  <p className="hover-layer my-1 mx-2 cursor-pointer" onClick={()=>handleOptions('DUPLICATE' )}>
    Duplicate
  </p>
  {/* <p onClick={() => {
      // setRightClickSelected(shape)
      // addComment(shape)
  }} className="hover-layer my-1 mx-2 cursor-pointer ">
    Add Comment
  </p> */}
</div>

                               )}
    </div>
  )
}

export default Layer