import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../Auth/Login/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import LandingPage from "../Pages/LandingPage/LandingPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
