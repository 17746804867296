import React from "react";
import Google from "../../Assets/Icons/Google.png";
import { Modal } from "react-bootstrap";
import "./Signup.css";

export default function Signup({
  show,
  handleClose,
  title,
  body,
  onClose,
  onSave,
  darkMode,
  handleShowLoginModal,
}) {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Body
        style={{
          backgroundColor: darkMode
            ? "var(--background-color-dark)"
            : "var(--background-color-light)",
        }}
      >
        <div
          className={`fa fa-close d-flex fs-5 ms-auto m-3 mt-0 me-0 cursor-pointer justify-content-end menuTextHeader rounded-5 ${
            darkMode ? "dark-mode" : ""
          }`}
          onClick={onClose}
        />
        <div className="row">
          <div className="col-lg-12 py-3 pt-0">
            <div className="loginSectionDiv d-flex flex-column align-items-center justify-content-center m-auto">
              <div className="headerLogin">
                <h1
                  className={`logoText loginTextColor mb-0 ${
                    darkMode ? "dark-mode" : ""
                  }`}
                >
                  Livesite
                </h1>
              </div>
              <div className="text-center py-3">
                <h3
                  className={`my-1 fw-bold menuTextHeader ${
                    darkMode ? "dark-mode" : ""
                  }`}
                >
                  Join our community
                </h3>
                <h5
                  className={`fw-normal my-3 menuTextHeader ${
                    darkMode ? "dark-mode" : ""
                  }`}
                >
                  Start your journey with our Livesuit
                </h5>
              </div>

              <div className="col-lg-10">
                <form className="px-0 mx-0 ">
                  <div className="form-group my-3 mt-0">
                    <label
                      for="exampleInputEmail1"
                      className={`py-2 yellowButtonColor ${
                        darkMode ? "dark-mode" : ""
                      }`}
                    >
                      Name*
                    </label>
                    <input
                      type="email"
                      className="form-control formLoginControl"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="form-group my-3">
                    <label
                      for="exampleInputPassword1"
                      className={`py-2 yellowButtonColor ${
                        darkMode ? "dark-mode" : ""
                      }`}
                    >
                      Email*
                    </label>
                    <input
                      type="email"
                      className="form-control formLoginControl"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                  </div>
                  <div className="form-group my-3">
                    <label
                      for="exampleInputPassword1"
                      className={`py-2 yellowButtonColor ${
                        darkMode ? "dark-mode" : ""
                      }`}
                    >
                      Password*
                    </label>
                    <input
                      type="password"
                      className="form-control formLoginControl"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-100 my-4 rounded-2 btn btn-primary"
                  >
                    Sign Up
                  </button>
                  <button
                    type="submit"
                    className="w-100 d-flex align-items-center justify-content-center my-3 mt-0 border rounded-2 btn btn-default"
                  >
                    <img src={Google} alt="Google" className="img-fluid px-2" />
                    Sign in with Google
                  </button>
                  <div className="text-center d-flex justify-content-center">
                    <div
                      className={`menuTextHeader ${
                        darkMode ? "dark-mode" : ""
                      }`}
                    >
                      Already have an account?
                    </div>
                    <label
                      className={`px-2 loginTextColor form-check-label  cursor-pointer  yellowButtonColor ${
                        darkMode ? "dark-mode" : ""
                      }`}
                      onClick={handleShowLoginModal}
                    >
                      Signin
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
