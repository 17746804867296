import React from "react";
import EditorCanvas from "../EditorLayout/EditorMainCanvasArea/EditorCanvas/EditorCanvas";
import EditorMainCanvasArea from "../EditorLayout/EditorMainCanvasArea/EditorMainCanvasArea";

export default function MainLayoutArea({ zoomLevel, filePreview,shapeToDraw, setShapeToDraw }) {
  return (

      <EditorMainCanvasArea filePreview={filePreview} zoomLevel={zoomLevel} shapeToDraw={shapeToDraw} setShapeToDraw={setShapeToDraw} />
  
  );
}
