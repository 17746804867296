import React, { useState } from "react";
import ToolSidebar from "../EditorLayout/EditorSidebar/ToolSidebar/ToolSidebar";
import PropertySidebar from "../EditorLayout/EditorSidebar/PropertySidebar/PropertySidebar";

export default function MainLeftSideBar({ setShapeToDraw }) {
  const [propertySidebarOpen, setPropertySidebarOpen] = useState(false);

  const togglePropertySidebar = () => {
    setPropertySidebarOpen(!propertySidebarOpen);
  };

  return (
    <>
      <div className="toolSidebar p-0">
        <ToolSidebar setShapeToDraw={setShapeToDraw} openPropertySidebar={togglePropertySidebar} />
        {propertySidebarOpen && <PropertySidebar />}
      </div>
    </>
  );
}
