import React, { useState,useRef } from "react";
import "../LandingLayout.css";
import defaultAvatar from "../../../Assets/Images/defaultAvatar.png";
import Notification from "../../../Assets/Icons/Notification.png";
import Settings from "../../../Assets/Icons/Setting.png";
import Login from "../../../Auth/Login/Login";
import Signup from "../../../Auth/Signup/Signup";
import Dark from "../../../Assets/Icons/Light.png";
import Light from "../../../Assets/Icons/Dark.png";
import Logo from '../../../Assets/Images/Logo.png'

export default function LandingHeader({
  toggleSidebar,
  darkMode,
  toggleTheme,
  filesPreview
}) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const fileInputRef = useRef(null); // Define fileInputRef
const [file,setFile]=useState({})
  // const [darkMode, setdarkMode] = useState(false);

  // const toggleTheme = () => {
  //   setdarkMode(!darkMode);
  // };
  const handleCloseModal = () => {
    setShowLoginModal(false);
    setShowSignupModal(false);
  };

  const handleShowLoginModal = () => {
    setShowLoginModal(true);
    setShowSignupModal(false); // Close signup modal if it's open
  };

  const handleShowSignupModal = () => {
    setShowSignupModal(true);
    setShowLoginModal(false); // Close login modal if it's open
  };

  const handleImageUpload = (event) => {
    // alert('hi')
    const file = event.target.files[0];
    setFile(file)
    filesPreview(file)
    if (!file) return;

    // Implement your file upload logic here
    console.log("Uploaded file:", file);
  };


  return (
    <>
      <div className={`header sticky-top ${darkMode ? "dark-mode" : ""}`}>
        <div className="d-flex align-items-center px-3">
          <div className="col-lg-2 d-flex align-items-center">
            <button
              className="hamburger bg-transparent border-0 ps-0"
              onClick={toggleSidebar}
            >
              <i
                className={`fa fa-bars fs-3 barsColor ${
                  darkMode ? "dark-mode" : ""
                }`}
              ></i>
            </button>
            <div className="brand-logo px-4 ms-2 d-flex align-items-center">
              <h1 className={`logoText mb-0 ${darkMode ? "dark-mode" : ""}`}>
              <img src={Logo} className="img-fluid" />
              </h1> 
            </div>
          </div>
          <div className="col-lg-10  d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h6
                className={`mb-0 px-3 ps-0 menuTextHeader ${
                  darkMode ? "dark-mode" : ""
                }`}
              >
                Home
              </h6>
              <h6
                className={`mb-0 px-3 menuTextHeader ${
                  darkMode ? "dark-mode" : ""
                }`}
              >
                File
              </h6>

                <div>

                  <input
        type="file"
        accept="image/*, .pdf"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageUpload}
    />
              <h6
                className={`mb-0 px-3 menuTextHeader cursor-pointer ${
                  darkMode ? "dark-mode" : ""
                }`}
                onClick={() => fileInputRef.current.click()}
                >
                Uploads
              </h6>
                </div>
              <h6
                className={`mb-0 px-3 menuTextHeader ${
                  darkMode ? "dark-mode" : ""
                }`}
              >
                Edit
              </h6>
              <h6
                className={`mb-0 px-3 menuTextHeader ${
                  darkMode ? "dark-mode" : ""
                }`}
              >
                View
              </h6>
              <h6
                className={`mb-0 px-3 menuTextHeader ${
                  darkMode ? "dark-mode" : ""
                }`}
              >
                Help
              </h6>
            </div>

            <div className="avatarSection d-flex align-items-center">
              <div className="notificationSection d-flex align-items-center">
                <button
                  onClick={toggleTheme}
                  className="d-flex align-items-center justify-content-center btn btn-default notificationSectionIcon mx-1"
                >
                  {darkMode ? (
                    <img src={Dark} alt="defaultAvatar" />
                  ) : (
                    <img src={Light} alt="defaultAvatar" />
                  )}
                </button>
                <button className="d-flex align-items-center justify-content-center btn btn-default notificationSectionIcon mx-1">
                  <img src={Notification} alt="defaultAvatar" />
                </button>{" "}
                <button className="d-flex align-items-center justify-content-center btn btn-default notificationSectionIcon mx-1">
                  <img src={Settings} alt="defaultAvatar" />
                </button>
                <button className="d-flex align-items-center justify-content-center btn btn-default notificationSectionIcon mx-1">
                  <img src={Notification} alt="defaultAvatar" />
                </button>{" "}
              </div>
              <div className="authSection">
                {/* <button className="btn btn-primary mx-1">
                  Create a design
                </button> */}
                <button
                  className="btn btn-primary mx-1 me-0"
                  onClick={handleShowLoginModal}
                >
                  Login
                </button>
                <button className="btn btn-default bg-transparent avtButton">
                  <img
                    src={defaultAvatar}
                    height={40}
                    width={40}
                    alt="defaultAvatar"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login
        handleShowSignupModal={handleShowSignupModal}
        show={showLoginModal}
        handleClose={handleCloseModal}
        title="Modal heading"
        body="Woohoo, you are reading this text in a modal!"
        onClose={handleCloseModal}
        darkMode={darkMode}
      />

      <Signup
        show={showSignupModal}
        handleShowLoginModal={handleShowLoginModal}
        handleClose={handleCloseModal}
        title="Modal heading"
        body="Woohoo, you are reading this text in a modal!"
        onClose={handleCloseModal}
        darkMode={darkMode}
      />
    </>
  );
}
