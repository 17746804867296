import React from 'react'
import LayerPanel from './LayerPanel/LayerPanel'

export default function MainRightSidebar() {
  return (
    <div className='border-1 '>
      <LayerPanel/>
    </div>
  )
}
