import React from 'react'
import LandingLayout from '../../Layout/LandingLayout/LandingLayout'

export default function LandingPage() {
  return (
    <div>
        <LandingLayout/>
    </div>
  )
}
