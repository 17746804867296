import React, { createContext, useContext, useReducer } from 'react';

// Initial state for shapes, properties, etc.

// here canvasData contains data for all the canvas related, and canvasShapes constains data of all canvas available, where each entry is a canvas, 
// whithing it, each entry contains { canvas-id, shapes, and userShapes }  

const dummyUsers=[ {
  // canvasId:"",
  id: 1,
  user: "Rishabh",
  shapes: []
},
{
canvasId:"",
   id: 2,
   user: "Rushabh",
   shapes: []
},
{
  canvasId:"",
   id: 3,
   user: "Vivek",
   shapes: []
}]
const initialState = {

  canvasData: {
    canvasShapes: [
      {
      canvasId: "canvas-1",
      shapes: [],
      userShapes: [  {
        // canvasId:"",
        id: 1,
        user: "Rishabh",
        shapes: []
     },
     {
      canvasId:"",
         id: 2,
         user: "Rushabh",
         shapes: []
      },
      {
        canvasId:"",
         id: 3,
         user: "Vivek",
         shapes: []
      } ],
    }
  ]
  },


  shapes: [],
  properties: {
    fillColor: "#ffffff",
    opacity: 0,
    fillType: "Solid",
    fillEnabled: true,
    color1: "#ffffff",
    color2: "#1DB8CE",
    pattern: "Diagonal",
    fontStyle: "Inter",
    fontSize: 10,
    fontWeight: "Bold",
    align: "left",
    decoration: "",
    strokeColor: "#000",
    strokeWidth: 2,
    strokeType: "Solid",
    strokeOpacity: 0,
  },
  users: [
    {
       id: 1,
       user: "Rishabh",
       shapes: []
    },
    {
        id: 2,
        user: "Rushabh",
        shapes: []
     },
     {
        id: 3,
        user: "Vivek",
        shapes: []
     } 
],
currentUser: 1,
selectedObject: null,
selectedObjIdx: -1,
commentAddEditFlag: false,
commentText: "",
titleText: "",
canvases: [{ id: 'canvas-1' }],
activeCanvas: 'canvas-1',
currentOption: null,
currentObj: null


// titleAddEditFlag : false,


  // Add other states as needed
};

// Action types
const ADD_SHAPE = 'ADD_SHAPE';
const REMOVE_SHAPE = 'REMOVE_SHAPE'
const UPDATE_SHAPE= 'UPDATE_SHAPE'
const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';

const ADD_USER_SHAPES = 'ADD_USER_SHAPES';
const REMOVE_USER_SHAPE=  'REMOVE_USER_SHAPE'

const UPDATE_CURRENT_USER= 'UPDATE_CURRENT_USER'
const UPDATE_CURRENT_OBJ = 'UPDATE_CURRENT_OBJ'
const RESET_SHAPE_PROPERTIES= 'RESET_SHAPE_PROPERTIES'
const UPDATE_COMMENT_FLAG= 'UPDATE_COMMENT_FLAG'
const UPDATE_COMMENT = 'UPDATE_COMMENT'
const UPDATE_TITLE = 'UPDATE_TITLE'
const ADD_CANVAS = 'ADD_CANVAS'
const UPDATE_ACTIVE_CANVAS = 'UPDATE_ACTIVE_CANVAS'
const UPDATE_OPTION= 'UPDATE_OPTION'


// Add more action types as needed
const UPDATE_CANVAS = 'UPDATE_CANVAS'; // New action type for updating canvas


function getNewUsersArr(userShape)
{
  const newUsers=[

  ]
   for(let i=0;i<dummyUsers.length;i++)
   {
    if(dummyUsers[i].id===userShape.currentUser)
    {
        const newDat={
          id: dummyUsers[i].id,
          user: dummyUsers[i].user,
          shapes: [userShape?.shape]
        }
        newUsers.push(newDat);
    }
    else{
      newUsers.push(dummyUsers[i]);
    }
   }
   return newUsers
}
// Reducer function
// Reducer function
const shapeReducer = (state, action) => {
  switch (action.type) {
    // case ADD_SHAPE:
    //   return {
    //     ...state,
    //     shapes: [...state.shapes, action.payload],
    //   };
    case 'ADD_SHAPE':
      const  newShape  = action.payload;
      const canvasIdx = newShape.canvasId;
    
      // Find the canvas with the provided canvasId
      const canvasIndex = state.canvasData.canvasShapes.findIndex(canvas => canvas.canvasId === canvasIdx);
    
      if (canvasIndex !== -1) {
        // If the canvas exists, make a copy of the canvasShapes array to update
        const updatedCanvasShapes = [...state.canvasData.canvasShapes];
        const canvas = updatedCanvasShapes[canvasIndex];
    
        // Check if the shape already exists in the canvas
        const existingShapeIndex = canvas.shapes.findIndex(shape => shape === newShape);
        if (existingShapeIndex === -1) {
          // If the shape doesn't exist in the canvas, add it
          canvas.shapes.push(newShape);
        }


      
    
        // Find the user in the canvas's userShapes array
        // const userIndex = canvas.userShapes.findIndex(user => user.id === userSpecificShape.currentUser);
    
        // if (userIndex !== -1) {
        //   // If the user exists, add the shape to their shapes array
        //   const user = canvas.userShapes[userIndex];
          
        //   // Check if the shape already exists in the user's shapes array
        //   const existingUserShapeIndex = user.shapes.findIndex(shape => shape === newShape);
        //   if (existingUserShapeIndex === -1) {
        //     // If the shape doesn't exist in the user's shapes array, add it
        //     user.shapes.push(newShape);
        //   }
        // } else {
        //   // If the user doesn't exist, create a new user entry and add the shape
        //   canvas.userShapes.push({
        //     id: userSpecificShape.currentUser,
        //     user: userSpecificShape.currentUser,
        //     shapes: [newShape]
        //   });
        // }
    
        // Update the canvasShapes array with the modified canvas
        updatedCanvasShapes[canvasIndex] = canvas;
    
        return {
          ...state,
          canvasData: {
            ...state.canvasData,
            canvasShapes: updatedCanvasShapes
          }
        };
      } else {
        // If the canvas doesn't exist, create a new canvas entry
        const newCanvas = {
          canvasIdx,
          shapes: [newShape],
          userShapes: dummyUsers
        };
    
        return {
          ...state,
          canvasData: {
            ...state.canvasData,
            canvasShapes: [...state.canvasData.canvasShapes, newCanvas]
          }
        };
      }
    case 'UPDATE_OPTION' :
      return {
        ...state, 
        currentOption: action.payload.currentOption,
        currentObj: action.payload.currentObj
      }
      case 'REMOVE_SHAPE':
        const { shapeId, canvasId2 } = action.payload;
      
        // Find the canvas with the provided canvasId
        const canvasIndex3 = state.canvasData.canvasShapes.findIndex(canvas => canvas.canvasId === canvasId2);
      
        if (canvasIndex3 !== -1) {
          // If the canvas exists, make a copy of the canvasShapes array to update
          const updatedCanvasShapes = [...state.canvasData.canvasShapes];
          const canvas = updatedCanvasShapes[canvasIndex3];
      
          // Find the index of the shape in the canvas's shapes array
          const shapeIndex = canvas.shapes.findIndex(shape => shape.shape === shapeId);
      
          if (shapeIndex !== -1) {
            // If the shape is found, remove it from the shapes array
            canvas.shapes.splice(shapeIndex, 1);
      
            // Update the canvasShapes array with the modified canvas
            updatedCanvasShapes[canvasIndex3] = canvas;
      
            return {
              ...state,
              canvasData: {
                ...state.canvasData,
                canvasShapes: updatedCanvasShapes
              }
            };
          }
        }
      
        // If the canvas or shape doesn't exist, return the current state
        return state;
      

    
    
    
    

      case UPDATE_SHAPE:
        const updatedShapes = state.shapes.map(shape => {
          if (shape.shape === action.payload.selectedShape) {
            // Update the shape with the new comment
            return {
              ...shape,
              [action.payload.label]: action.payload.item,
            };
          }
          return shape;
        });
  
        return {
          ...state,
          shapes: updatedShapes,
        };
    case UPDATE_PROPERTIES:
      return {
        ...state,
        properties: { ...state.properties, [action.payload.field]: action.payload.value },
      };
      case ADD_USER_SHAPES :
        const { currentUser, shape, canvasId } = action.payload;
      
        // Find the canvas with the provided canvasId
        const canvasIndex2 = state.canvasData.canvasShapes.findIndex(canvas => canvas.canvasId === canvasId);
      
        if (canvasIndex2 !== -1) {
          // If the canvas exists, make a copy of the canvasShapes array to update
          const updatedCanvasShapes = [...state.canvasData.canvasShapes];
          const canvas = updatedCanvasShapes[canvasIndex2];
      
          // Find the user in the canvas's userShapes array
          const userIndex = canvas.userShapes.findIndex(user => user.id === currentUser);
      
          if (userIndex !== -1) {
            // If the user exists, check if the shape already exists in their shapes array
            const user = canvas.userShapes[userIndex];
            const existingShapeIndex = user.shapes.findIndex(s => s === shape);
            
            if (existingShapeIndex === -1) {
              // If the shape doesn't exist in the user's shapes array, add it
              const updatedUserShapes = [...canvas.userShapes];
              updatedUserShapes[userIndex] = {
                ...user,
                shapes: [...user.shapes, shape]
              };
              
              // Update the canvas's userShapes array with the modified user
              canvas.userShapes = updatedUserShapes;
      
              // Update the canvasShapes array with the modified canvas
              updatedCanvasShapes[canvasIndex2] = canvas;
      
              return {
                ...state,
                canvasData: {
                  ...state.canvasData,
                  canvasShapes: updatedCanvasShapes
                }
              };
            }
          }
        }
      
        // If the canvas or user doesn't exist, return the current state
        return state;
        case 'REMOVE_USER_SHAPE':
          const { canvasId4, userId, shapeId2 } = action.payload;
        
          // Find the canvas with the provided canvasId
          const canvasIndex4 = state.canvasData.canvasShapes.findIndex(canvas => canvas.canvasId === canvasId4);
        
          if (canvasIndex4 !== -1) {
            // If the canvas exists, make a copy of the canvasShapes array to update
            const updatedCanvasShapes = [...state.canvasData.canvasShapes];
            const canvas = updatedCanvasShapes[canvasIndex4];
        
            // Find the user in the canvas's userShapes array
            const userIndex = canvas.userShapes.findIndex(user => user.id === userId);
        
            if (userIndex !== -1) {
              // If the user exists, find the shape in the user's shapes array
              const user = canvas.userShapes[userIndex];
              const shapeIndex = user.shapes.findIndex(shape => shape === shapeId2);
        
              if (shapeIndex !== -1) {
                // If the shape is found, remove it from the user's shapes array
                user.shapes.splice(shapeIndex, 1);
        
                // Update the canvas's userShapes array with the modified user
                const updatedUserShapes = [...canvas.userShapes];
                updatedUserShapes[userIndex] = user;
                canvas.userShapes = updatedUserShapes;
        
                // Update the canvasShapes array with the modified canvas
                updatedCanvasShapes[canvasIndex4] = canvas;
        
                return {
                  ...state,
                  canvasData: {
                    ...state.canvasData,
                    canvasShapes: updatedCanvasShapes
                  }
                };
              }
            }
          }
        
          // If the canvas, user, or shape doesn't exist, return the current state
          return state;
        
      
    // Handle other actions here
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UPDATE_CURRENT_OBJ: 
      return {
        ...state,
        selectedObject: action.payload?.selectedObj,
        selectedObjIdx: action.payload?.selectedObjIdx,
      };
    case RESET_SHAPE_PROPERTIES: 
      return {
        ...state,
        properties:{
          ...state.properties,
          fillColor: "#ffffff",
          opacity: 0,
          fillType: "Solid",
          fillEnabled: true,
          color1: "#ffffff",
          color2: "#1DB8CE",
          pattern: "Diagonal",
          fontStyle: "Inter",
          fontSize: 10,
          fontWeight: "Bold",
          align: "left",
          decoration: "",
          strokeColor: "#000",
          strokeWidth: 2,
          strokeType: "Solid",
          strokeOpacity: 0,
        },
      };
    case UPDATE_COMMENT_FLAG:
      return {
        ...state,
        commentAddEditFlag: action.payload,
      };
    case UPDATE_COMMENT: 
      return {
        ...state, 
        commentText: action.payload,
      };
    case UPDATE_TITLE: 
      return {
        ...state, 
        titleText: action.payload,
      };
      case 'ADD_CANVAS':
        // Generate a unique canvas ID
        const newCanvasId = action.payload;
        
        // Create a new canvas object
        const newCanvas = { id: newCanvasId };
        
        // Add the new canvas to the 'canvases' array
        const updatedCanvases = [...state.canvases, newCanvas];
        
        // Create a new entry in the 'canvasData.canvasShapes' array
        const newCanvasShapesEntry = {
          canvasId: newCanvasId,
          shapes: [],
          userShapes: dummyUsers
        };
        
        // Update the 'canvasId' for the userShapes
        const updatedCanvasShapes = [...state.canvasData.canvasShapes];
        // updatedCanvasShapes.forEach(canvas => {
        //   canvas.canvasId = newCanvasId;
        // });
      
        return {
          ...state,
          canvases: updatedCanvases,
          canvasData: {
            ...state.canvasData,
            canvasShapes: [...updatedCanvasShapes, newCanvasShapesEntry]
          }
        };
    case UPDATE_ACTIVE_CANVAS: 
      return {
        ...state, 
        activeCanvas: action.payload,
      };
    case UPDATE_CANVAS: 
      const updatedShapesCanvas = state.shapes.map(shape => {
        // Handle different shape types
        switch (shape.type) {
          case 'triangle':
          case 'rectangle':
          case 'ellipse': // Assuming ellipse represents a circle
          case 'hexagon':
            return {
              ...shape,
              canvas: action.payload,
            };
          default:
            return shape;
        }
      });

      return {
        ...state, 
        shapes: updatedShapesCanvas,
      };
    default:
      return state;
  }
};


// Create the context
const ShapeContext = createContext();

// Create a custom hook to use the context
export const useShapeContext = () => {
  const context = useContext(ShapeContext);
  if (!context) {
    throw new Error('useShapeContext must be used within a ShapeProvider');
  }
  return context;
};

// Create the provider component
export const ShapeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shapeReducer, initialState);

  // Actions
  const addShape = (shape) => {
    dispatch({ type: ADD_SHAPE, payload: shape });
  };

  const removeShape = (shape) => {
    dispatch({type: REMOVE_SHAPE, payload:shape})
  }

  const updateShape = (shape) => {
    dispatch({ type: UPDATE_SHAPE, payload: shape });
  };

  const updateActiveCanvas = (canvasId) => {
    dispatch({ type: UPDATE_ACTIVE_CANVAS, payload: canvasId });
  };
  
  const addCanvas = (canvasId) => {
    dispatch({ type: ADD_CANVAS, payload: canvasId });
  };

  const resetShape = () => {
    dispatch({ type: RESET_SHAPE_PROPERTIES });
  };

  const updateCommentAddEditFlag = (value) => {
    dispatch({ type: UPDATE_COMMENT_FLAG, payload: value });
  };

  const updateCanvas = (canvasData) => {
    dispatch({ type: UPDATE_CANVAS, payload: canvasData });
  };

  const updateProperties = (updatedProperties) => {
 
    dispatch({ type: UPDATE_PROPERTIES, payload: updatedProperties });
  };

  const addUserShapes = (userShape) => {
    dispatch({ type: ADD_USER_SHAPES, payload: userShape });
  };
  const removeUserShape = (userShape) => {
    
    dispatch({type: REMOVE_USER_SHAPE, payload: userShape})

  }

  const updateCurrentUser = (newUserId) => {
    dispatch({ type: UPDATE_CURRENT_USER, payload: newUserId });
  };

  const updateCurrentObj = (newObj) => {
    dispatch({type:UPDATE_CURRENT_OBJ, payload: newObj})
  }
  const updateCurrentOpt = (newOpt) => {
   
    dispatch({type:UPDATE_OPTION, payload: newOpt})
  }
  const updateCommentText = (comment) => {
    dispatch({ type: UPDATE_COMMENT, payload: comment });
  };

  const updateTitleText = (title) => {
    dispatch({ type: UPDATE_TITLE, payload: title });
  };

  // Provide the state and actions to the Components
  const contextValue = {
    state,
    actions: {
      addShape,
      updateShape,
      addUserShapes,
      updateCurrentUser,
      updateCurrentObj,
      resetShape,
      updateCommentAddEditFlag,
      updateCommentText,
      updateProperties,
      updateTitleText,
      addCanvas,
      updateActiveCanvas,
      updateCurrentOpt,
      removeShape,
      removeUserShape,
      
      // Add more actions here
      updateCanvas,
    },
  };

  return (
    <ShapeContext.Provider value={contextValue}>
      {children}
    </ShapeContext.Provider>
  );
};
