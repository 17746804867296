import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./LandingRecentDesign.css";
import Cards from '../../../../Components/Cards/Cards'

// Placeholder image
import placeholderImage from "../../../../Assets/Images/CardImg.png";

export default function LandingRecentDesign({  darkMode }) {
  // Generating an array of 50 elements for rendering 50 cards
  const cardData = Array.from({ length: 30 }, (_, index) => ({
    id: index,
    imageSrc: placeholderImage,
    imageAlt: `Card ${index + 1} image`,
    title: `Untitled Design ${index + 1}`,
    text: "Whiteboard",
  }));

  return (
    <section className={`LandingRecentDesign ${darkMode ? "dark-mode" : ""}`}>
      <div className="row mx-0">
        <div className="col-lg-12">
          <div className="searchTextBox d-flex justify-content-center py-5">
            <InputGroup className="mb-3 w-50">
              <Form.Control
                placeholder="Search whatever you want..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                className="searchTextInput"
              />
              <Button
                className="btn btn-primary px-4 searchTextButton fs-5"
                id="button-addon2"
              >
                SEARCH
              </Button>
            </InputGroup>
          </div>
          <div className="recentDesignCards">
            <h5
              className={`text-start menuTextHeader fw-bold ${
                darkMode ? "dark-mode" : ""
              }`}
            >
              Recent Design
            </h5>
            <div className="row py-4">
              {cardData.map((card) => (
                <div key={card.id} className="col-lg-2 my-3">
                  <Cards
                    imageSrc={card.imageSrc}
                    imageAlt={card.imageAlt}
                    title={card.title}
                    text={card.text}
                    darkMode={darkMode}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
