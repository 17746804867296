import React, { useState,useEffect } from "react";
import "./PropertySidebar.css";
import { OverlayTrigger, Tooltip, Form, InputGroup } from "react-bootstrap";
import { fabric } from "fabric";
import left from "../../../../../Assets/Icons/alignment-left.png";
import middleV from "../../../../../Assets/Icons/alignment-middle-v.png";
import right from "../../../../../Assets/Icons/alignment-right.png";
import top from "../../../../../Assets/Icons/alignment-top.png";
import middleH from "../../../../../Assets/Icons/alignment-middle-h.png";
import bottom from "../../../../../Assets/Icons/alignment-bottom.png";
import dH from "../../../../../Assets/Icons/distribute-horizontal-center.png";
import dV from "../../../../../Assets/Icons/distribute-vertical-center.png";
import FillSettings from "./FillSettings/FillSettings";
import TextSettings from "./TextSettings/TextSettings";
import StrokeSettings from "./StrokeSettings/StrokeSettings";
import CommentSettings from "./CommentSettings/CommentSettings";
import TitleSettings from "./TitleSettings/TitleSettings";
import IconSettings from "./IconSettings/IconSettings";
import { useShapeContext } from "../../../../../contexts/shapeContext";
import prop from "../../../../../Assets/Icons/Property.png";

export default function PropertySidebar() {
  const { state, actions } = useShapeContext();
  const {selectedObject} = state;


  const [fillType, setFillType] = useState("Solid");
  const [fillColor,setFillColor]= useState("#ffffff");
  const [color1, setColor1] = useState("#1DB8CE");
  const [color2, setColor2] = useState("#FFFFFF");
  const[ fillEnabled,setFillEnabled] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null); // Define selectedItem state
  const [selectedPattern, setSelectedPattern] = useState("Diagonal");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  // const [strokeColor,setStrokeColor] = useState("#000000")
  // const [strokeWidth,setStrokeWidth] = useState(2)
  // const [strokeType,setStrokeType]= useState("Solid")
  const [count, setCount] = useState(0);


  const fillProps={
    fillType,
    setFillType,
    fillColor,
    setFillColor,
    color1,
    setColor1,
     color2,
    setColor2, 
    fillEnabled,setFillEnabled,
    selectedPattern, setSelectedPattern,
  }

  // const strokeProps={
  //   strokeColor,
  //   setStrokeColor,
  //   strokeWidth,
  //   setStrokeWidth, 
  //   strokeType,
  //   setStrokeType,
  //   count, setCount
  // }

  const {
    //  fillColor,
     opacity,
    //  fillType,
    //  fillEnabled,
    //  color1,
    //  color2,
     pattern,
     
     fontStyle,
     fontSize,
     fontWeight,
     align,
     decoration,
    
     strokeColor,
     strokeWidth,
     strokeType,
     strokeOpacity,
   

    } = state.properties;



  useEffect(() => {
    // Update the input fields when a new object is selected
    if (selectedObject) {
      setWidth(selectedObject.width || 0);
      setHeight(selectedObject.height || 0);
      setXPosition(selectedObject.left || 0);
      setYPosition(selectedObject.top || 0);
    }
  }, [selectedObject]);

  const handleWidthChange = (event) => {
    const newWidth = parseInt(event.target.value);
    setWidth(newWidth);
    updateShapeProperty("width", newWidth);
  };

  const handleHeightChange = (event) => {
    const newHeight = parseInt(event.target.value);
    setHeight(newHeight);
    updateShapeProperty("height", newHeight);
  };

  const handleXPositionChange = (event) => {
    const newXPosition = parseInt(event.target.value);
    setXPosition(newXPosition);
    updateShapeProperty("left", newXPosition);
  };

  const handleYPositionChange = (event) => {
    const newYPosition = parseInt(event.target.value);
    setYPosition(newYPosition);
    updateShapeProperty("top", newYPosition);
  };


  
  function changeFontProperty(field,value){
    // console.log("Chanign this ", field,value);
    updateShapeProperty(field,value)
    const payload={field,value}
    actions.updateProperties(payload);
  }

  function changeStrokeProperty(field,value){
    // console.log("Chanign this ", field,value);
    updateShapeProperty(field,value)
    const payload={field,value}
    actions.updateProperties(payload);
  }

  function changeTextProperty(field , value)
  {
    // console.log("Chanign this ", field,value);
    updateShapeProperty(field,value)
    const payload={field,value}
    actions.updateProperties(payload);
  }



  const capitalizeFirstLetter = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  function updateShapeProperty(field,value)
  {
    console.log("FINAL change ", field, value);
    if (selectedObject) {
      selectedObject.set({ [field]: value });
      selectedObject.setCoords(); // Update the coordinates after setting new properties
      actions.updateCanvas(); // Trigger canvas update
    }
     switch (field) {
      case "fillColor":
        selectedObject.set({
          fill:  getFillValue(field,value)
        })
        break;
      case "fillType": 
      selectedObject.set({
        fill: getFillValue(field,value)
      })
      break;
      case "color1": 
      selectedObject.set({
        fill: getFillValue(field,value)
      })
      break;
      case "color2": 
      selectedObject.set({
        fill: getFillValue(field,value)
      })
      break;
      case "pattern": 
      selectedObject.set({
        fill: getFillValue(field,value)
      })
      break;
      case "strokeColor": 
      selectedObject.set({
        stroke: value || "#000000"
      })
      break;
      case "strokeWidth": 
      selectedObject.set({
        strokeWidth: strokeWidth || 2
      })
      break;
      case "strokeType": 
      selectedObject.set({
        strokeDashArray: value === "Dashed"
        ? [5, 5]
        : value === "Dotted"
        ? [1, 5]
        : null,
      })
      break;
      case "fontSize": 
      selectedObject.set(field,value ? value : '12');
      break;
      case "fontFamily": 
      selectedObject.set("fontFamily", value ? value : '');
      break;
      case "fontWeight": 
      selectedObject.set(field, value ? value : '');
      break;
      case "textAlign": 
      selectedObject.set(field, value ? value : '');
      break;
      case "textDecoration": 
          switch (value) {
            case "underline":
              selectedObject.set("underline", true);
              selectedObject.set("overline", false);
              selectedObject.set("linethrough", false);
              break;
              case "overline":
                selectedObject.set("underline", false);
                selectedObject.set("overline", true);
                selectedObject.set("linethrough", false);
              break;
                case "linethrough":
                  selectedObject.set("underline", false);
                  selectedObject.set("overline", false);
                  selectedObject.set("linethrough", true);
              break;
            default:
              break;
          }
      break;
      case "textTransform":
        switch (value) {
          case "Uppercase":
            selectedObject.set("text", selectedObject.text.toUpperCase());
            break;
          case "Lowercase":
            selectedObject.set("text", selectedObject.text.toLowerCase());
            break;
          case "Capitalize":
            selectedObject.set("text", capitalizeFirstLetter(selectedObject.text));
            break;
          default:
            // Default case
            break;
        }
      break;
       

      default:
        break;
     }
  }

 
  const getFillValue = (field,value) => {
    // console.log("Getting hatch type is ", field,value);

   if(field==="fillType"){
    switch (value) {
      case "Solid":
        return fillEnabled ? fillColor : "";
      case "Gradient":
        return createGradientFill();
      case "Image":
        return createImageFill();
      case "Pattern":
        return createHatchFill(value);
      default:
        return "";
    }
  }
  // else if(field === "pattern"){
   
  // }
  else{
    switch (fillType) {
      case "Solid":
        return fillEnabled ? fillColor : "";
      case "Gradient":
        return createGradientFill();
      case "image":
        return createImageFill();
      case "Pattern":
        return createHatchFill(value);
      default:
        return "";
    }
  }
  };

  const createGradientFill = () => {
    const gradient = new fabric.Gradient({
      type: "linear",
      gradientUnits: "pixels",
      coords: { x1: 0, y1: 0, x2: 0, y2: 200 },
      colorStops: [
        { offset: 0, color: color1 },
        { offset: 1, color: color2 },
      ],
    });
    return gradient;
  };

  const createImageFill = () => {
    // return imageURL;
  };

  const createHatchFill = (pattern) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    
    // console.log("DIAGONAL", pattern);
    canvas.width = 20;
    canvas.height = 20;

    switch (pattern) {
      case "Diagonal":
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(20, 20);
        ctx.stroke();
        break;
      case "Horizontal":
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;

        ctx.beginPath();
        ctx.moveTo(0, 10);
        ctx.lineTo(20, 10);
        ctx.stroke();
        break;
      case "Vertical":
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;

        ctx.beginPath();
        ctx.moveTo(10, 0);
        ctx.lineTo(10, 20);
        ctx.stroke();
        break;
      default:
        ctx.strokeStyle = strokeColor;
        ctx.lineWidth = strokeWidth;

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(20, 20);
        ctx.stroke();
    }

    const patternType = new fabric.Pattern({
      source: canvas,
      repeat: "repeat",
    });

    return patternType;
  };


  return (
    <section className="pt-2 position-relative">
      <div className="">
        <label className="w-100 labelProp d-inline-block pb-2 border-bottom border-2 text-start py-2">
          <img src={prop} className="img-fluid px-2"/>
          Property Explorer
        </label>
        {/* <nav id="main-menu">
          <ul className="nav-bar ps-0 d-flex justify-content-between">
            <li className="nav-button-home ps-0">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="align-left-tooltip">Align Left</Tooltip>}
              >
                <a href="#">
                  <img src={left} className="" alt="Align Left" />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-services">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="align-middle-v-tooltip">
                    Align Middle Vertically
                  </Tooltip>
                }
              >
                <a href="#">
                  <img
                    src={middleV}
                    className=""
                    alt="Align Middle Vertically"
                  />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-products">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="align-top-tooltip">Align Right</Tooltip>}
              >
                <a href="#">
                  <img src={right} className="" alt="Align Right" />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-products">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="align-top-tooltip">Align Top</Tooltip>}
              >
                <a href="#">
                  <img src={top} className="" alt="Align Top" />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-home">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="align-middle-h-tooltip">
                    Align Middle Horizontally
                  </Tooltip>
                }
              >
                <a href="#">
                  <img
                    src={middleH}
                    className=""
                    alt="Align Middle Horizontally"
                  />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-services">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="align-bottom-tooltip">Align Bottom</Tooltip>
                }
              >
                <a href="#">
                  <img src={bottom} className="" alt="Align Bottom" />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-products">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="distribute-h-tooltip">
                    Distribute Horizontally
                  </Tooltip>
                }
              >
                <a href="#">
                  <img src={dH} className="" alt="Distribute Horizontally" />
                </a>
              </OverlayTrigger>
            </li>
            <li className="nav-button-products">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="distribute-v-tooltip">
                    Distribute Vertically
                  </Tooltip>
                }
              >
                <a href="#">
                  <img src={dV} className="" alt="Distribute Vertically" />
                </a>
              </OverlayTrigger>
            </li>
          </ul>
        </nav> */}

        <nav id="main-menu" className="p-2 pt-0">
        <ul className="nav-bar d-flex justify-content-between mt-0 ps-0 col-lg-10">
            <li className="nav-button-home">
              <div className="d-flex align-items-center propertyTextContainer my-4 mb-0">
                <InputGroup className="pe-0 ">
                  <InputGroup.Text className="propertyTextBox border-end-0 border-start-0 border-top-0 border-bottom-0">
                    X
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={xPosition}
                    onChange={handleXPositionChange}
                    className="propertyTextBox border-start  border-top-0 border-bottom-0 border-end-0"
                    placeholder="X Position"
                  />
                </InputGroup>
                <InputGroup className=" pe-0 ps-4">
                  <InputGroup.Text className="propertyTextBox border-start-0 border-top-0 border-bottom-0">
                    Y
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={yPosition}
                    onChange={handleYPositionChange}
                    className="propertyTextBox border-start  border-top-0 border-bottom-0 border-end-0 "
                    placeholder="Y Position"
                  />
                </InputGroup>
              </div>
            </li>
          </ul>

          <ul className="nav-bar d-flex justify-content-between mt-0 ps-0 col-lg-10">
            <li className="nav-button-home">
              <div className="d-flex align-items-center propertyTextContainer ">
                <InputGroup className="">
                  <InputGroup.Text className="propertyTextBox border-end-0 border-start-0 border-top-0 border-bottom-0">
                    W
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={width}
                    onChange={handleWidthChange}
                    className="propertyTextBox border-start  border-top-0 border-bottom-0 border-end-0"
                    placeholder="Width"
                  />
                </InputGroup>
                <InputGroup className=" pe-0 ps-4">
                  <InputGroup.Text className="propertyTextBox border-start-0 border-top-0 border-bottom-0">
                    H
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    value={height}
                    onChange={handleHeightChange}
                    className="propertyTextBox border-start  border-top-0 border-bottom-0 border-end-0 "
                    placeholder="Height"
                  />
                </InputGroup>
              </div>
            </li>
          </ul>

          <hr className="hrColor py-0 my-0" />
          <FillSettings changeProperty={changeFontProperty} fillProps={fillProps} />
          <hr className="hrColor py-0 my-0" />
          <TextSettings changeProperty={changeTextProperty} />
          <hr className="hrColor py-0 my-0" />
          <StrokeSettings changeProperty={changeStrokeProperty}  />
          <hr className="hrColor py-0 my-0" />
          <CommentSettings/>
          <hr className="hrColor py-0 my-0" />
          <TitleSettings/>
          {/* <hr className="hrColor py-0 my-0" /> */}
          <IconSettings/>

          {/* <ul className="nav-bar d-flex justify-content-between ps-0">
            <li className="nav-button-home">
              <div className="d-flex align-items-center propertyTextContainer my-4 mt-2">
                <InputGroup className="">
                  <InputGroup.Text className="propertyTextBox border-end-0">
                    X
                  </InputGroup.Text>
                  <Form.Control
                    id="inlineFormInputGroup"
                    className="propertyTextBox"
                    placeholder="000"
                  />
                </InputGroup>
                <InputGroup className=" pe-0 ps-4">
                  <InputGroup.Text className="propertyTextBox border-end-0">
                    X
                  </InputGroup.Text>
                  <Form.Control
                    id="inlineFormInputGroup"
                    className="propertyTextBox"
                    placeholder="000"
                  />
                </InputGroup>
              </div>
            </li>
          </ul> */}
        </nav>
      </div>
    </section>
  );
}
