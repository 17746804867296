import React, { useEffect, useState } from "react";
import layer from "../../../../Assets/Icons/Layers.png";
import plus from "../../../../Assets/Icons/plus-sign.png";
import up from "../../../../Assets/Icons/TriangleUp.png";
import minus from "../../../../Assets/Icons/TriangleDown.png";
import Lock from "../../../../Assets/Icons/Lock.png";
import Hide from "../../../../Assets/Icons/EyeHide.png";
import TextICO from "../../../../Assets/Icons/TextIcoLayer.png";
import ImgICO from "../../../../Assets/Icons/ImageIcoLayer.png";

import Square from "../../../../Assets/Icons/square.png";
import Rect from "../../../../Assets/Icons/rectangular.png";
import circle from "../../../../Assets/Icons/circle.png";
import triangle from "../../../../Assets/Icons/triangle.png";
import Hexa from "../../../../Assets/Icons/hexagon.png";
import star from "../../../../Assets/Icons/star.png";


import FreeDrawIcon from "../../../../Assets/Icons/FreeDraw.png";
import ShapesIcon from "../../../../Assets/Icons/ShapesIcon.png";
import LineIcon from "../../../../Assets/Icons/LineIcon.png";

import "./LayerPanel.css";
// import CommonDropdown from "../../../../Components/CommonDropdown/CommonDropdown";
// import CommonDropdown from "../../../../Components/CommonDropdown/CommonDropdown";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { useShapeContext } from "../../../../contexts/shapeContext";
import Layer from "./Layer";

export default function LayerPanel() {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [currActiveOpt, setCurrentActiveOpt] = useState();
  const [users, setUsers] = useState([]);
  const { state, actions } = useShapeContext();
  const {  canvasData, shapes, currentUser,activeCanvas } = state;

  useEffect(()=>{
    const currCanvasData = canvasData.canvasShapes.filter((canvasShape)=> canvasShape.canvasId===activeCanvas);
    // console.log("currCanvasData", currCanvasData); 
    if(currCanvasData.length>0)
    {
      setUsers(currCanvasData[0].userShapes)
    }

  }, [activeCanvas,canvasData])



  



  function handleAccordionToggle(userId) {
    // console.log("Clicked Accordion with User ID:", userId);
    actions.updateCurrentUser(userId);
    setIsAccordionOpen((prev) => !prev);
  }

  function getImgType(userShape)
  {
    // console.log("Image help type of shape", userShape);
    const typeOfShape= userShape?.type;


    switch (typeOfShape) {
      case "rect":
        return Rect;
        break;
      case "circle":
        return circle;
        break;
      case "square":
        return Square;
        break;
      case "line":
        return LineIcon;
        break;
      case "i-text":
        return TextICO;
        break;
      case "Triangle":
        return triangle;
        break;
      case "Star":
        return star;
        break;
      case "polygon":
        return Hexa;
        break;
      case "FreeForm":
        // toggleDrawPolygon();
        break;

      default:
        break;
    }
  }

  return (
    <div>
      <div className="LayerSection px-1">
        <div className="LayerHeading">
          <div className="row d-flex align-items-center py-2 border-bottom border-2">
            <div className="col-lg-6 d-flex align-items-center justify-content-start pe-5">
              <img src={layer} className="d-flex ms-auto px-2" />
              <label className=" pe-5">Layers</label>
            </div>
            <div className="col-lg-6 invisible border-start">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <Form.Select
                    className="border-0 "
                    aria-label="Default select example"
                  >
                    <option>Page 1</option>
                    <option value="1">2</option>
                    <option value="2">3</option>
                    <option value="3">4</option>
                  </Form.Select>
                </div>
                <div className="addLayer cursor-pointer">
                  <img src={plus} className="d-flex adIc  ms-auto px-2" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="LayerList my-3">
          <div className="Layer col-lg-5 w-100">
            <Accordion
              defaultActiveKey={currentUser}
              className="accMain border-0"
            >
              {users &&
                users?.map((user, idx) => (
                  <Accordion.Item
                    eventKey={user?.id}
                    className="border-0 accItem"
                    key={idx}
                  >
                    <Accordion.Header
                      className={`accHeaderMain border-0 cursor-pointer d-flex align-items-center justify-content-between text-black  fs-6 ${
                        isAccordionOpen ? "" : "" // Add the class if the accordion is open
                      }`}
                      onClick={() => handleAccordionToggle(user?.id)}
                    >
                      <div className="d-flex  align-items-center">
                        {isAccordionOpen ? (
                          <img
                            src={up}
                            className={`img-fluid adI d-flex ms-auto fs-5 ${
                              isAccordionOpen ? "openHeaderImg" : "" // Apply open image class
                            }`}
                            alt="Left"
                          />
                        ) : (
                          <img
                            src={minus}
                            className={`img-fluid adI d-flex ms-auto fs-5 ${
                              isAccordionOpen ? "openHeaderImg" : "" // Apply open image class
                            }`}
                            alt="Left"
                          />
                        )}
                        <label
                          htmlFor="propertyName"
                          className={`mt-1 ps-2 propertyTextLabel ${
                            isAccordionOpen ? "openHeaderText" : "" // Apply open text color class
                          }`}
                        >
                          {`User ${user?.id}`}
                        </label>
                      </div>
                      <div className="d-flex ms-auto">
                        <img
                          src={Hide}
                          className={`d-flex filImg ms-auto ${
                            isAccordionOpen ? "openHeaderImg1" : "" // Apply open image class
                          }`}
                        />
                        <img
                          src={Lock}
                          className={`d-flex filImg ms-auto ${
                            isAccordionOpen ? "openHeaderImg1" : "" // Apply open image class
                          }`}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="bodyLayer border-0 px-3">
                      <div className="fillSettings">
                        <div className="row  d-flex flex-column position-relative">
                          {user.shapes &&
                            user.shapes?.map((userShape, userIdx) => (

                              <Layer 
                                 userShape={userShape} 
                                 getImgType={getImgType} 
                                 currActiveOpt={currActiveOpt} 
                                 setCurrentActiveOpt={setCurrentActiveOpt}
                                 index={userIdx} />
                              // <div className="d-flex flex-row justify-content-between align-items-center ">
                               
                              // <div className="iconText w-auto  me-auto ps-4 py-2 pt-0">
                              //   <img
                              //     src={getImgType(userShape)}
                              //     className="img-fluid"
                              //     alt="shape-img"
                              //   />
                              //   <label className="ps-2">
                              //     {userShape?.type}
                              //   </label>
                              // </div>
                              // <img
                              //     src={threeDot}
                              //     className="img-fluid cursor-pointer"
                              //     alt="three-dot"
                              //     width={20}
                              //   />
                              // </div>
                            ))}

                          {/* <div className="iconText d-flex ms-auto ps-4 py-2 pt-0">
            <img src={TextICO} className="img-fluid" />
            <label className="ps-2">Display Title 3</label>
          </div>
          <div className="iconText d-flex ms-auto ps-4 py-2">
            <img src={ImgICO} className="img-fluid" />
            <label className="ps-2">Image Layer</label>
          </div> */}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
