import React from "react";
import "../LandingLayout.css";
import LandingRecentDesign from "./LandingRecentDesign/LandingRecentDesign";

export default function LandingContentArea({ sidebarOpen,  darkMode, toggleTheme }) {
  return (
<div className={`main ${sidebarOpen ? "sidebar-open" : ""} ${darkMode ? "dark-mode" : ""}`}>
      <LandingRecentDesign darkMode={darkMode} />
    </div>
  );
}
