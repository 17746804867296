const Data = [
    {
      id: 1,
      username: "admin@test.com",
      password: "admin",
      firstName: "Admin",
      lastName: "A",
      role: "Admin",
    },
    {
      id: 2,
      username: "user@test.com",
      password: "user",
      firstName: "User",
      lastName: "U",
      role: "User",
    },
  ];
  
  export default Data;
  