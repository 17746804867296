// LandingSidebar.js
import React from "react";
import defaultAvatar from "../../../Assets/Images/defaultAvatar.png";
import Home from "../../../Assets/Icons/Home.png";
import DarkHome from "../../../Assets/Icons/DarkHome.png";
import Folder from "../../../Assets/Icons/Folder.png";
import DarkFolder from "../../../Assets/Icons/DarkFolder.png";

import DarkDelete from "../../../Assets/Icons/DarkDelete.png";
import Delete from "../../../Assets/Icons/Delete.png";
import Team from "../../../Assets/Icons/Team.png";
import DarkTeam from "../../../Assets/Icons/DarkTeam.png";

import Paper from "../../../Assets/Icons/Paper.png";
import DarkPaper from "../../../Assets/Icons/DarkPaper.png";

import "./LandingSidebar.css";

export default function LandingSidebar({ sidebarOpen, darkMode, toggleTheme }) {
  const sidebarClass = `sidebar px-4 py-3 ${sidebarOpen ? "open px-0" : ""} ${
    darkMode ? "dark-mode" : ""
  }`;
  return (
    <div id="sidenav" className={sidebarClass}>
      <div className="d-flex flex-column ">
        <div className="d-flex flex-column ">
          <div className="loginAvatarSection py-2">
            <div className="d-flex align-items-center">
              <img
                src={defaultAvatar}
                height={40}
                width={40}
                alt="defaultAvatar"
              />
              <div className="d-flex flex-column text-start">
                {/* <h1 className={`logoText mb-0 ${darkMode ? "dark-mode" : ""}`}> */}

                <small
                  className={`fw-medium lightText fs-6 mx-2 ${
                    darkMode ? "dark-mode" : ""
                  }`}
                >
                  Personal
                </small>
                <div className="d-flex align-items-center">
                  <small className="fw-normal avatarText mx-2">Free</small>
                  <i className="fa fa-user avatarText"></i>
                  <small className="fw-normal avatarText mx-2">1</small>
                </div>
              </div>
            </div>
          </div>
          <div className="sideMenuSection my-3">
            <a
              className={`active d-flex align-items-center ${
                darkMode ? "dark-mode" : ""
              }`}
              href="#home"
            >
              <img
                src={darkMode ? DarkHome : Home}
                className="img-fluid pe-3"
                alt="defaultAvatar"
              />
              Home
            </a>

            <a
              href="#news"
              className={`lightText ${darkMode ? "dark-mode" : ""}`}
            >
              {" "}
              <img
                              src={darkMode ? DarkFolder : Folder}
                className="img-fluid pe-3"
                alt="defaultAvatar"
              />
              Projects
            </a>
   
          </div>
        </div>
      </div>
      <div className="d-flex flex-column position-absolute bottomMenu pe-4">
        <a
          className={`d-flex align-items-center lightText ${
            darkMode ? "dark-mode" : ""
          }`}
          href="#home"
        >
          <img src={darkMode ? DarkTeam : Team} className="img-fluid pe-3" alt="defaultAvatar" />
          Create a team
        </a>
        <hr  className={`py-0 my-0 lightText opacity-100 ${
                    darkMode ? "dark-mode" : ""
                  }`} />
        <a href="#news" className={`yellowButtonColor ${darkMode ? "dark-mode" : ""}`}>
          {" "}
          <img src={darkMode ? DarkDelete : Delete} className="img-fluid pe-3" alt="defaultAvatar" />
          Trash
        </a>
      </div>
    </div>
  );
}
